{
    "common": {
        "ogram": "Ogram",
        "start_now": "Ξεκινήστε Τώρα",
        "dismiss": "Κλείσιμο",
        "continue": "Συνέχεια",
        "text_copied": "Το κείμενο αντιγράφηκε στο πρόχειρο",
        "tc": "Όροι και Προϋποθέσεις",
        "shifts": "βάρδιες",
        "show_more": "Δείξε Περισσότερα",
        "ogs": "OGs",
        "i_accept_terms": "Αποδέχομαι τους <1>Όρους Χρήσης</1>",
        "i_accept": "Αποδέχομαι τους",
        "terms_of_service": "Όρους Χρήσης",
        "yes": "Ναι",
        "no": "Όχι",
        "id_n": "ID#{{id}}",
        "loading": "Φορτώνει...",
        "app_version": "App Version: {{version}}",
        "select": "Επιλογή",
        "job_n": "Δουλειά #{{id}}",
        "filters": "Filters",
        "filter": "Filter",
        "location": "Τοποθεσία",
        "designation": "Ειδικότητα",
        "change": "Αλλαγή",
        "cancel": "Ακύρωση",
        "order_id": "Αριθμός Παραγγελίας",
        "is_overdue": "Έχουν καθυστερήσει",
        "date_from": "Ημερομηνία Έναρξης",
        "date_to": "Ημερομηνία Λήξης",
        "now": "Τώρα",
        "no_data_found": "Δεν βρέθηκαν στοιχεία",
        "not_available": "Δεν είναι διαθέσιμο"
    },
    "password": {
        "minimumChars": "+6 χαρακτήρες",
        "oneLowercase": "1 πεζό γράμμα",
        "oneNumber": "1 αριθμός",
        "oneUppercase": "1 κεφαλαίο γράμμα"
    },
    "login": {
        "good_to_see_you_back": "Χαιρόμαστε πολύ που σας βλέπουμε",
        "sign_in_to_account": "Συνδεθείτε στον λογαριασμό σας",
        "enter_email": "Προσθέστε το email σας",
        "enter_password": "Συμπληρώστε τον κωδικό σας",
        "email": "Email",
        "password": "Κωδικός",
        "remember_me": "Να με θυμάσαι",
        "forgot_password_question": "Ξεχάσατε τον κωδικό σας;",
        "sign_in": "Σύνδεση",
        "signin_in": "Σας συνδέουμε...",
        "sign_up_link_text": "Εγγραφή",
        "test_string": "Δοκιμή του συστήματος"
    },
    "create_password": {
        "title": "Δημιουργήστε έναν κωδικό",
        "description": "Δημιουργήστε έναν κωδικό πρόσβασης για να μπορείτε να συνδέεστε με το email σας",
        "please_enter_password": "Συμπληρώστε τον κωδικό σας",
        "please_confirm_password": "Επιβεβαιώστε τον κωδικό σας",
        "confirm_password": "Επιβεβαίωση κωδικού",
        "password": "Κωδικός",
        "password_matched": "Οι Κωδικοί Ταιριάζουν",
        "password_not_matched": "Οι Κωδικοί Δεν Ταιριάζουν",
        "resend_modal_button": "Επαναποστολή email",
        "sending": "Αποστολή...",
        "email_invalid": "Το email δεν είναι έγκυρο",
        "email_required": "Συμπληρώστε το email σας",
        "email": "Email",
        "email_is_sent": "Ένας νέος σύνδεσμος έχει σταλεί στο email σας.",
        "submit": "Υποβολή"
    },
    "forgot_password": {
        "title": "Ξεχάσαστε τον κωδικό σας;",
        "description": "Προσθέστε το email που συνδέεται με τον λογαριασμό σας",
        "please_enter_email": "Συμπληρώστε το email σας",
        "email_must_be_valid": "Το email δεν είναι έγκυρο",
        "email": "Email",
        "submit": "Υποβολή",
        "back_to_sign_in": "Επιστροφή στη Σύνδεση",
        "forgot_password": "Ξέχασα τον κωδικό",
        "password_sent_to_email": "Ένας νέος κωδικός έχει σταλεί στο email σας.",
        "resend": "Επαναποστολή",
        "reset_password": "Επαναφορά κωδικού",
        "email_sent": "Ένας σύνδεσμος επαναφοράς κωδικού πρόσβασης έχει σταλεί στo email σας"
    },
    "validation_messages": {
        "email_invalid": "Το email δεν είναι έγκυρο"
    },
    "reset_password": {
        "title": "Επαναφορά κωδικού",
        "description": "Προσθέστε νέο κωδικό",
        "please_enter_password": "Προσθέστε νέο κωδικό",
        "please_confirm_password": "Επιβεβαιώστε τον κωδικό σας",
        "password": "Κωδικός",
        "confirm_password": "Επιβεβαίωση κωδικού",
        "password_mathched": "Οι κωδικοί ταιριάζουν",
        "password_not_matched": "Οι κωδικοί δεν ταιριάζουν",
        "submit": "Υποβολή"
    },
    "change_password": {
        "title": "Αλλάξτε κωδικό πρόσβασης",
        "description": "Εισάγετε τον παλιό και τον νέο κωδικό πρόσβασης",
        "please_enter_old_password": "Εισάγετε τον παλιό κωδικό πρόσβασης",
        "please_enter_password": "Εισάγετε τον νέο κωδικό πρόσβασης",
        "please_confirm_password": "Επιβεβαιώστε τον νέο κωδικό πρόσβασης",
        "old_password": "Παλιός Κωδικός Πρόσβασης",
        "new_password": "Νέος Κωδικός Πρόσβασης",
        "confirm_new_password": "Επιβεβαιώστε τον νέο κωδικό",
        "password_matched": "Οι κωδικοί ταιριάζουν",
        "password_not_matched": "Οι κωδικοί δεν ταιριάζουν",
        "submit": "Υποβολή",
        "password_changed_successfully": "Ο κωδικός πρόσβασης άλλαξε με επιτυχία"
    },
    "sign_up": {
        "title": "Εγγραφή Πελάτη",
        "subtitle": "Εγγραφείτε για να μπορείτε να δημιουργείτε και να ελέγχετε τις παραγγελίες σας",
        "sign_up": "Εγγραφή",
        "first_name": "Όνομα",
        "last_name": "Επίθετο",
        "phone": "Τηλέφωνο",
        "email": "Email",
        "password": "Κωδικός πρόσβασης",
        "city": "Πόλη",
        "company_name_optional": "Όνομα εταιρείας (Απαιτείται για εταιρείες)",
        "company_name": "Όνομα εταιρείας",
        "agree_to_emails": "Συμφώνω να λαμβάνω ειδοποιήσεις για λόγους marketing από την Ogram",
        "are_you_corporate_question": "Είστε εταιρεία;",
        "back_to_sign_in": "Πίσω στη Σύνδεση",
        "first_name_required": "Προσθέστε το όνομά σας",
        "last_name_required": "Προσθέστε το επίθετό σας",
        "phone_required": "Προσθέστε το τηλέφωνό σας",
        "email_required": "Προσθέστε το email σας",
        "company_name_required": "Το όνομα της εταιρείας είναι απαραίτητο για εταιρικούς χρήστες",
        "city_required": "Προσθέστε την πόλη σας",
        "submitting": "Φόρτωση...",
        "choose_work_category_title": "Τι προσωπικό χρειάζεστε;",
        "choose_work_category_subtitle": "Περιγράψτε την θέση εργασίας",
        "create_new_order_title": "Ξεκινήστε να Προσλαμβάνετε",
        "contact_details_title": "Ποια είναι τα στοιχεία επικοινωνίας σας;",
        "contact_details_subtitle": "Προσθέστε τα στοιχεία επικοινωνίας σας για να επικοινωνήσουμε μαζί σας",
        "how_to_reach_title": "Πως μπορούμε να επικοινωνήσουμε;",
        "next": "Επόμενο",
        "phone_invalid": "Πληκτρολογήστε ένα έγκυρο τηλέφωνο",
        "already_registered": "Έχετε ήδη λογαριασμό;",
        "sign_in": "Συνδεθείτε",
        "consent": "Κάνοντας κλικ στο κουμπί \"Εγγραφή\", χορηγείτε άδεια στην εταιρεία μας να χρησιμοποιήσει τα προσωπικά σας στοιχεία για τη δημιουργία λογαριασμού και την επεξεργασία της παραγγελίας σας.",
        "thank_you": "Σας ευχαριστούμε!"
    },
    "verify_email": {
        "title": "Επιβεβαιώστε το email σας",
        "description": "Ο κωδικός επιβεβαίωσης έχει σταλεί στο email σας",
        "we_sent_code_to_email": "Στείλαμε τον κωδικό επαλήθευσης στο {{email}}. Ελέγξτε τα εισερχόμενά σας και προσθέστε τον κωδικό επαλήθευσης παρακάτω για να ολοκληρώσετε την δημιουργία του λογαριασμού σας.",
        "please_enter_code": "Προσθέστε τον κωδικό επαλήθευσης",
        "verification_code": "Κωδικός Επαλήθευσης",
        "verify": "Επαλήθευση",
        "did_not_receive_email_question": "Δεν λάβατε το email; Επανάληψη αποστολής κωδικού σε 3 λεπτά",
        "not_your_email_question": "Δεν είναι το email σας;",
        "change_email": "Αλλάξτε το email σας"
    },
    "applicant_details": {
        "n_shifts_completed": "{{count}} ολοκληρωμένες βάρδιες",
        "information": "Πληροφορίες",
        "work_experience": "Επαγγελματική Εμπειρία",
        "education": "Εκπαίδευση",
        "get_next_applicant": "Επόμενος υποψήφιος",
        "in": "μέσα",
        "chief_og": "Αρχηγός OG",
        "added_as_favourite": "Ο/Η {{spName}} βρίσκεται πλέον στη λίστα των προτιμήσεών μας. Θα δίνουμε προτεραιότητα στον/στην {{spName}} στον αλγόριθμό μας.",
        "skills": "Δεξιότητες",
        "chief": "Οι <1>Αρχηγοί OG's </1><2>είναι έμπειροι στην ηγεσία ομάδων και τους έχουμε επιλέξει με προσοχή για να συντονίζουν καλύτερα τις ομάδες της Ogram στον χώρο εργασίας.</2>",
        "interview": "Συνέντευξη",
        "view_answer": "Δείτε την Απάντηση",
        "job_history_with_you": "Ιστορικό Συνεργασίας με Εσάς"
    },
    "applicants_review": {
        "title": "Έλεγχος Υποψηφίων",
        "no_applicants_to_reivew": "Δεν υπάρχουν υποψήφιοι για έλεγχο",
        "percent_filled": "{{percent}}% Γεμάτο"
    },
    "job_cherry_picking": {
        "title": "Λεπτομέρειες Εργασίας",
        "accepted": "Δεκτή",
        "rejected": "Απορρίφθηκε",
        "reject": "Απόρριψη",
        "accept": "Αποδοχή",
        "exp": "Εμπειρία",
        "age": "Ηλικία",
        "language": "Γλώσσα",
        "nationality": "Εθνικότητα",
        "years_of_experience": "{{count}} Χρόνια",
        "experience": "Εμπειρία",
        "height": "Ύψος",
        "height_cm": "{{count}} εκ",
        "years_old": "{{count}} χρονών",
        "total_applicants": "Υποψήφιοι ({{count}})",
        "sort_by": "Ταξινόμηση κατά:",
        "rating": "Βαθμολογία",
        "see_more": "Δείτε Περισσότερα",
        "confirmation": "Επιβεβαίωση",
        "you_are_about_to_accept": "Πρόκειται να αποδεχτείτε τον/την <1>{{name}}</1> για την θέση",
        "you_are_about_to_reject": "Πρόκειται να απορρίψετε τον/την <1>{{name}}</1>. Θα αφαιρεθεί από την λίστα",
        "confirm": "Επιβεβαίωση",
        "cancel": "Ακύρωση",
        "jobID": "Κωδικός Θέσης",
        "designation": "Ειδικότητα",
        "location": "Τοποθεσία",
        "required_quantity": "Απαιτούμενος Αριθμός",
        "fulfillment": "Εκπλήρωση",
        "fulfillmentRatio": "{{percent}}%",
        "start_date": "Ημ/νία Έναρξης",
        "end_date": "Ημ/νία Λήξης",
        "seniority_level": "Επίπεδο Εμπειρίας",
        "congratulation_exclamation": "Συγχαρητήρια!",
        "you_have_assigned_staff": "Έχετε αναθέσει όλο το απαιτούμενο προσωπικό",
        "done": "Τέλος",
        "skill_match": "Ταίριασμα Δεξιοτήτων",
        "chief_title": "Έχουμε Κάτι Για Εσάς",
        "chief_subtitle": "Προσθέστε έναν Αρχηγό OG στην ομάδα σας - είναι δωρεάν!",
        "chief_og": "Αρχηγός OG",
        "what_is_chief": "Τι είναι ένας Αρχηγός OG;",
        "chief_definition": "Ο Αρχηγός OG είναι ένας ηγέτης ομάδας, επιλεγμένος προσεκτικά από εμάς για να βοηθά στη διαχείριση των μεγαλύτερων ομάδων της Ogram. Για παράδειγμα, επιλύοντας διαφωνίες και συντονίζοντας τα μέλη της ομάδας - χωρίς επιπλέον κόστος για εσάς. \nΚαι όλο αυτό είναι <1>εντελώς δωρεάν για εσάς.</1>",
        "chief_button_label": "Ακούγεται καλό",
        "time": "Χρόνος",
        "applicant_assigned": "Ο υποψήφιος έχει ανατεθεί!",
        "shortlisted": "Λίστα Επικρατέστερων"
    },
    "interview": {
        "applicants_finished": "Δεν υπάρχουν άλλες απαντήσεις προς αξιολόγηση",
        "applicant_id": "ID#{{id}}",
        "next_question": "Επόμενη Ερώτηση",
        "accept": "Αποδοχή",
        "assigned": "Ανατέθηκε",
        "next_applicant": "Επόμενος υποψήφιος",
        "question_short": "Ε{{index}}:",
        "rating_question_short": "Αξιολόγηση Ε{{index}}:",
        "recommended_to_hire": "Προτεινόμενοι για Πρόσληψη",
        "overall_rating": "Συνολικές Αξιολογήσεις",
        "video_player_not_supported": "Ο browser σας δεν υποστηρίζει την αναπαραγωγή βίντεο. Παρακαλώ δοκιμάστε να τον ενημερώσετε ή χρησιμοποιήστε άλλον browser.",
        "submitting_rating": "Υποβολή αξιολόγησης...",
        "rating_applied": "Η αξιολόγηση εφαρμόστηκε!"
    },
    "complete_registration": {
        "title": "Εγγραφή Εταιρείας",
        "legal_information": "Νομικές Πληροφορίες",
        "field_required": "Αυτό το πεδίο είναι υποχρεωτικό",
        "yes": "Ναι",
        "no": "Όχι",
        "company_name": "Όνομα Εταιρείας",
        "trade_name_as_license": "Εμπορική Ονομασία σύμφωνα με την Άδεια",
        "trade_license_number": "Αριθμός Εμπορικής Άδειας",
        "legal_address": "Διεύθυνση Επιχείρησης",
        "po_box": "Ταχυδρομικός Κώδικας",
        "free_zone": "Ελεύθερη Ζώνη",
        "lpo_required": "Απαιτείται Σύμβαση Παροχής Υπηρεσιών",
        "region": "Περιοχή",
        "vat_certificate": "Πιστοποιητικό Φ.Π.Α.",
        "upload_vat": "Προσθέστε το Πιστοποιητικό του ΦΠΑ",
        "i_agree_with_terms": "Συμφωνώ με την <1>Πολιτική Απορρήτου</1>",
        "i_agree_with_company_pp": "Συμφωνώ με την <1>Πολιτική Απορρήτου Για Εταιρίες</1>",
        "i_agree_with_company_terms": "Συμφωνώ με τους <1>Όρους και τις Προϋποθέσεις</1>",
        "continue": "Συνέχεια",
        "gr": {
            "company_name": "Επωνυμία Επιχείρησης",
            "address": "Διεύθυνση: Οδός-Αριθμός-Πόλη",
            "tax_jurisdiction": "Δ.Ο.Υ.",
            "registry_number": "ΑΡ. ΓΕΜΗ",
            "zip_code": "Ταχυδρομικός Κώδικας",
            "industry": "Τομέας Δραστηριότητας",
            "industry_retail": "Λιανεμπόριο",
            "industry_hotels": "Ξενοδοχειακά",
            "industry_brc": "Bar/Εστατόριο/Καφέ"
        },
        "document_selected": "Tα Έγγραφα Επιλέχθηκαν",
        "document_submitted": "Τα Έγγραφα Υποβλήθηκαν"
    },
    "change_schedule_options": {
        "all_dates_from_date": "Όλες οι ημέρες από {{date}}",
        "date_only": "Μόνο {{date}}",
        "every_day_from_date": "Κάθε {{day}} από {{date}}",
        "custom": "Προσαρμογή"
    },
    "onboarding_complete": {
        "title": "Σας ευχαριστούμε!",
        "description": "Έχουμε λάβει τις πληροφορίες της εταιρείας σας.",
        "done": "Τέλος"
    },
    "sign_sla": {
        "title": "Υπογράψτε τη Συμφωνία Παροχής Υπηρεσιών",
        "i_agree": "Συμφωνώ"
    },
    "home": {
        "hello_name": "Γεια σας, <1>{{name}}</1>",
        "hello": "Γεια σας",
        "shifts_attendance_review": "Έλεγχος παρουσίας βαρδιών",
        "orders_to_confirm": "Παραγγελίες για Επιβεβαίωση",
        "rating_requests": "Αιτήματα αξιολόγησης",
        "applicant_review": "Έλεγχος υποψηφίων",
        "pending_payments": "Εκκρεμείς Πληρωμές",
        "error_failed_to_load_statistics": "Αποτυχία φόρτωσης στατιστικών",
        "error_failed_to_load_home_page": "Αποτυχία φόρτωσης αρχικής σελίδας",
        "coming_soon": {
            "hello": "Γεια σας",
            "we_are_coming_soon": "Σύντομα Κοντά Σας",
            "prepare_for_launch": "Τώρα και στην Ελλάδα! Η προετοιμασία έχει ήδη ξεκινήσει",
            "on_demand_staff": "Προσωπικό Κατ' Απαίτηση",
            "find_workers": "Βρείτε και προσλάβετε το κατάλληλο προσωπικό στo πιο απλό και εξειδικευμένο app.",
            "short_term_contracts": "Βραχυπρόθεσμα Συμβόλαια",
            "post_jobs": "Δημοσιεύστε προσωρινές ή μακροχρόνιες θέσεις εργασίας και καλύψτε την επόμενη βάρδιά σας εντός 48 ωρών από την εγγραφή σας.",
            "incredible_savings": "Εξοικονομήστε Χρήματα",
            "save_money": "Πληρώνετε μόνο τις ώρες εργασίας"
        }
    },
    "interview_popup": {
        "title": "Συνέντευξη μέσω Βίντεο",
        "description": "Ενσωματώστε τις συνεντεύξεις μέσω βίντεο στις προσπάθειες πρόσληψης σας για να εξοικονομήσετε ώρες για την ομάδα σας και να επιταχύνετε τις διαδικασίες.",
        "create_order_button": "Δημιουργήστε μια Παραγγελία"
    },
    "sidebar": {
        "company": "Εταιρεία",
        "error_failed_to_load_sidebar": "Αποτυχία φόρτωσης πλαϊνής μπάρας. Προσπαθήστε ξανά",
        "create_order": "Δημιουργία Παραγγελίας",
        "register_corporate": "Εγγραφή Επιχείρησης",
        "account": "Λογαριασμός",
        "manage_locations": "Διαχείρηση Τοποθεσιών",
        "users": "Χρήστες",
        "knowledge_center": "Κέντρο Γνώσης",
        "home": "Αρχική",
        "all_jobs": "Όλες οι Θέσεις Εργασίας",
        "attendance_review": "Ανασκόπηση Παρουσιών",
        "orders": "Παραγγελίες",
        "applicants_review": "Έλεγχος Υποψηφίων",
        "rating_requests": "Αιτήματα αξιολόγησης",
        "invoices": "Τιμολόγια",
        "payments": "Πληρωμές",
        "payment_methods": "Τρόποι πληρωμής",
        "billing": "Τιμολόγηση",
        "orders_uppercase": "Παραγγελίες"
    },
    "all_jobs": {
        "title": "Ημερολόγιο",
        "day": "Ημέρα",
        "3_days": "3 Μέρες",
        "week": "Εβδομάδα",
        "month": "Μήνας",
        "percent_filled": "{{count}}% Γεμάτο",
        "start": "Έναρξη",
        "end": "Λήξη",
        "manage_shift": "Διαχείριση βάρδιας",
        "unsaved_changes": "Έχετε μη αποθηκευμένες αλλαγές. Είστε σίγουροι ότι θέλετε να αφήσετε αυτή τη σελίδα;",
        "shift_canceled": "Η βάρδια έχει ακυρωθεί",
        "og_checked_in": "Ο Ogrammer έχει ξεκινήσει την βάρδια",
        "og_checked_out": "Ο Ogrammer έχει ολοκληρώσει την βάρδια",
        "assigned_to": "Ανατέθηκε στον/στην",
        "no_one": "Κανένα",
        "empty_slot": "Κενή θέση",
        "select_OG": "Επιλέξτε OG",
        "cancel": "Ακύρωση",
        "save_changes_count": "Αποθήκευση Αλλαγών ({{count}})",
        "update_success": "Επιτυχής ενημέρωση",
        "accepted": "Έγιναν Αποδεκτές",
        "canceled": "Ακυρώθηκαν",
        "completed": "Ολοκληρώθηκαν",
        "irrelevant": "Μη σχετικό",
        "pending": "Σε εκκρεμότητα",
        "started": "Έχουν Ξεκινήσει",
        "extension_announcement": "You can now extend orders directly from this page. Click the three dots next to the order and select \"Extend Order.\""
    },
    "job_shifts": {
        "title": "Βάρδιες",
        "change_schedule": "Αλλαγή προγράμματος",
        "cancel_shift": "Ακύρωση βάρδιας",
        "confirmation_question": "Πρόκειται να ακυρώσετε την εργασία για όλους τους Ogrammers, είστε σίγουροι για αυτό;",
        "i_have_capacity": "Έχω δυνατότητα",
        "select_reason": "Επιλέξτε Αιτιολογία",
        "other": "Άλλο",
        "comment_here": "Σχολιάστε εδώ (Προαιρετικό)",
        "cancel_job": "Ακυρώστε την εργασία",
        "cancel": "Ακύρωση",
        "set_start_time": "Ρυθμίστε την ώρα έναρξης",
        "set_end_time": "Ρυθμίστε την ώρα λήξης",
        "start_time": "Ώρα Έναρξης",
        "end_time": "Ώρα Λήξης",
        "apply_schedule": "Εφαρμογή Προγράμματος",
        "apply_on_days": "Εφαρμογή σε συγκεκριμένες ημέρες από {{date}}",
        "confirm": "Επιβεβαίωση",
        "pending": "Σε εκκρεμότητα",
        "started": "Έχουν ξεκινήσει",
        "check_in": "Έναρξη βάρδιας",
        "check_out": "Λήξη βάρδιας",
        "extend_shift": "Επέκταση Βάρδιας",
        "call": "Κλήση",
        "whatsapp": "WhatsApp",
        "remove_from_favourites": "Αφαίρεση από τα αγαπημένα",
        "add_to_favourites": "Προσθήκη στα αγαπημένα",
        "extra_time": "Επιπλέον Χρόνος",
        "percent_filled": "{{percent}}% Γεμάτο",
        "for_the_job": "για την θέση",
        "cancel_all_following_shifts": "Ακύρωση όλων των επόμενων βαρδιών",
        "block_ogrammer": "Αποκλεισμός Ogrammer"
    },
    "location_list": {
        "title": "Τοποθεσίες",
        "add_location": "Προσθήκη Τοποθεσίας",
        "location_required": "Το όνομα της τοποθεσίας είναι υποχρεωτικό",
        "address_required": "Η διεύθυνση είναι υποχρεωτική",
        "address_details_required": "Απαιτούνται πρόσθετες πληροφορίες διεύθυνσης",
        "region_required": "Η περιοχή είναι υποχρεωτική",
        "location_name": "Όνομα τοποθεσίας",
        "region": "Περιοχή",
        "location_address": "Διεύθυνση Τοποθεσίας",
        "address_details": "Επιπλέον πληροφορίες διεύθυνσης",
        "upload_location_image": "Ανεβάστε φωτογραφία της τοποθεσίας (Προαιρετικό)",
        "save": "Αποθήκευση",
        "delete": "Διαγραφή",
        "check_in_restrictions_title": "Περιορισμοί Check-in",
        "large_area": "Πολυκατάστημα ή Συνοικιακή Τοποθεσία",
        "no_restrictions": "Απενεργοποίηση περιορισμού γεωεντοπισμού",
        "additional_details": "Επιπρόσθετες Πληροφορίες",
        "restrictions_info_modal_text": "Ενεργοποιήστε την επιλογή “<strong> Πολυκατάστημα ή Συνοικιακή Τοποθεσία</strong>“, εάν το προσωπικό πρέπει να εργαστεί σε πολύ μεγάλες εγκαταστάσεις. Θα προσαρμόσουμε ανάλογα την ακτίνα της ζώνης check-in και check-out.<br/><br/>Η επιλογή “<strong>Απενεργοποίηση περιορισμού γεωγραφικής τοποθεσίας</strong>“ θα καταργήσει τους γεωγραφικούς περιορισμούς σε περίπτωση απομακρυσμένης εργασίας ή εάν η εργασία θα πραγματοποιηθεί σε πολλές τοποθεσίες.",
        "you_have_n_locations": "Έχετε {{locationsCount}} τοποθεσίες"
    },
    "add_location": {
        "title": "Προσθήκη Τοποθεσίας"
    },
    "edit_location": {
        "title": "Επεξεργασία Τοποθεσίας"
    },
    "orders_list": {
        "title": "Παραγγελίες",
        "empty_list_title": "Ξεκινήστε να Προσλαμβάνετε Σήμερα!",
        "empty_list_description": "Αναζητάτε προσωπικό προσωρινής απασχόλησης; \nΕνημερώστε μας για την θέση.",
        "create_order": "Δημιουργία Παραγγελίας",
        "tab_title_pending": "Σε εκκρεμότητα",
        "tab_title_active": "Ενεργή",
        "tab_title_completed": "Ολοκληρωμένη"
    },
    "order": {
        "order_number": "Παραγγελία #{{id}}",
        "order_summary": "Σύνοψη Παραγγελίας",
        "jobs": "Θέσεις Εργασίας",
        "cost": "Κόστος",
        "i_accept_terms": "Αποδέχομαι τους <1>Όρους Χρήσης</1>",
        "confirm": "Επιβεβαίωση",
        "order_confirmed": "Επιβεβαίωση Παραγγελίας",
        "thank_you_for_confirming_order": "Ευχαριστούμε για την επιβεβαίωση της παραγγελίας σας.",
        "done": "Ολοκλήρωση",
        "payment_terms": "Όροι Πληρωμής",
        "complete_company_details": "Συμπληρώστε τα στοιχεία της εταιρείας για να αλλάξετε τους όρους πληρωμής",
        "change_terms": "Αλλαγή Όρων",
        "duplicate_order": "Αντιγραφή Παραγγελίας",
        "days": "Ημέρες",
        "to_end": "μέχρι το Τέλος",
        "location": "Τοποθεσία",
        "end_date": "Ημ/νία Λήξης",
        "start_date": "Ημ/νία Έναρξης",
        "subtotal": "Μερικό Σύνολο",
        "transportations": "Μεταφορικά",
        "vat_percent": "Φ.Π.Α. ({{count}}%)",
        "estimated_total": "Εκτιμώμενο Σύνολο",
        "dates": "Ημερομηνίες",
        "days_count": "(Αριθμός Ημερών: {{count}})",
        "hours": "Ώρες",
        "hours_amount": "Αριθμός Ωρών: {{hours}}",
        "hours_per_day": "({{hoursPerDay}} ώρες/ημέρα)",
        "rate": "Ωρομίσθιο",
        "hour_rate": "{{currency}} {{hourRate}}/ώρα",
        "transportation_cost": "{{currency}} {{cost}}/ημέρα",
        "category_schedule": "Πρόγραμμα {{category}}",
        "order_summary_days_count": "(Αριθμός Ημερών: {{count}})",
        "hello_name": "Γεια σου, <1>{{name}}</1>",
        "confirm_order": "Έχουμε λάβει αίτημα νέας παραγγελίας από εσάς. Για να προχωρήσουμε, παρακαλούμε ελέγξτε και επιβεβαιώστε τα παρακάτω στοιχεία.",
        "pending_rates_review": "Το αίτημα παραγγελίας σας είναι υπό εξέταση. Θα σας ειδοποιήσουμε μόλις οριστικοποιηθούν οι χρεώσεις.",
        "send_for_review": "Αποστολή για Έλεγχο",
        "under_review": "Υπό Εξέταση",
        "final_amount_will_be_based": "*Το τελικό συνολικό ποσό θα προκύψει από την πραγματική συμμετοχή",
        "amend_order": "Τροποποίηση Παραγγελίας",
        "i_accept": "Αποδοχή των",
        "terms_of_service": "Όρων Χρήσης",
        "select_amend_reason": "Επιλέξτε την Αιτία Τροποποίησης",
        "select_reason": "Επιλέξτε την Αιτία",
        "submitting": "Υποβολή...",
        "continue": "Συνέχεια",
        "cancel": "Ακύρωση",
        "quotation_download_title": "Λήψη Προσφοράς",
        "quotation_download_action_text": "Λήψη",
        "quotation_download_in_progress_text": "Γίνεται Λήψη...",
        "lpo_upload_title": "Ανεβάστε    την Υπογεγραμμένη Σύμβαση Παροχής Υπηρεσιών",
        "lpo_upload_action_text": "Ανεβάστε",
        "lpo_upload_in_progress_text": "Ανεβαίνει...",
        "lpo_upload_success_message": "Η Σύμβαση Παροχής Υπηρεσιών έχει ανέβει επιτυχώς",
        "menu_download_quote": "Κατεβάστε την Προσφορά",
        "menu_upload_lpo": "Ανεβάστε την Σύμβαση Παροχής Υπηρεσιών",
        "lpo_download_title": "Κατεβάστε την Σύμβαση Παροχής Υπηρεσιών",
        "lpo_download_in_progress_text": "Γίνεται Λήψη...",
        "menu_duplicate_order": "Αντιγράψτε την παραγγελία",
        "draft_order_title": "Προσχέδιο Παραγγελίας",
        "job_to_duplicate": "Εργασία προς Αντιγραφή",
        "need_to_review_corporate_details": "Έχουμε λάβει νέο αίτημα παραγγελίας από εσάς. Για να προχωρήσουμε, παρακαλούμε ελέγξτε και συμπληρώστε τα στοιχεία της εταιρείας",
        "corporate_details_are_being_reviewed": "Εξετάζουμε τα στοιχεία της εταιρείας που μας στείλατε",
        "complete_company_details_link": "Συμπληρώστε τα στοιχεία της εταιρείας",
        "suggest_extension": "Σας ευχαριστούμε που επιλέξατε τις υπηρεσίες μας. Επεκτείνετε την παραγγελία σας για επιπλέον υπηρεσίες ή προϊόντα.",
        "extend_your_order": "Επέκταση Παραγγελίας"
    },
    "job_draft_category": {
        "title": "Δημιουργία Νέας Παραγγελίας",
        "tell_about_job": "Πείτε μας για την θέση εργασίας",
        "tell_about_first_job": "Πείτε μας για την πρώτη θέση εργασίας",
        "find_staff": "Βρείτε εξειδικευμένο προσωπικό για κάθε σας ανάγκη"
    },
    "job_draft_details": {
        "title": "Θέση Εργασίας: {{category}}",
        "fill_job_details": "Συμπληρώστε τα στοιχεία της θέσης",
        "staff": "Προσωπικό",
        "quantity": "Ποσότητα",
        "location": "Τοποθεσία",
        "start_date": "Ημ/νία Έναρξης",
        "end_date": "Ημ/νίας Λήξης",
        "days": "Ημέρες",
        "start_time": "Ώρα Έναρξης",
        "end_time": "Ώρα Λήξης",
        "requirements": "Απαιτήσεις",
        "job_description": "Περιγραφή Θέσης Εργασίας",
        "experience": "Εμπειρία",
        "dresscode": "Ενδυματολογικός Κώδικας",
        "additional_preferences": "Επιπλέον Προτιμήσεις",
        "languages": "Γλώσσες",
        "gender": "Φύλο",
        "additional_documents": "Πρόσθετα Έγγραφα",
        "video_interview": "Συνέντευξη μέσω Βίντεο",
        "manual_selection": "Χειροκίνητη Επιλογή Προσωπικού",
        "accreditation": "Απαιτείται Διαπίστευση",
        "submitting": "Υποβολή...",
        "save": "Υποβολή",
        "finish": "Τέλος",
        "next": "Επόμενο",
        "create_duplicate": "Αποθήκευση",
        "work_category_required": "Παρακαλώ επιλέξτε κατηγορία εργασίας",
        "step_n_of_m_steps": "{{objectNameLabel}} Βήμα {{index}} από {{count}}",
        "quantity_required": "Ορίστε πόσο προσωπικό χρειάζεστε",
        "location_required": "Ορίστε την τοποθεσία",
        "schedule_type_required": "Παρακαλώ επιλέξτε τύπο προγράμματος",
        "same_time_prompt_required": "Παρακαλούμε ενημερώστε μας αν όλες οι ημέρες έχουν την ίδια ώρα έναρξης/λήξης",
        "start_date_required": "Ορίστε ημερομηνία έναρξης",
        "end_date_required": "Ορίστε την ημερομηνία λήξης ή κάντε την εργασία ανοιχτή",
        "end_date_cannot_be_before_start_date": "Η ημερομηνία λήξης δεν μπορεί να προηγείται της ημερομηνίας έναρξης",
        "start_time_required": "Ορίστε την ώρα έναρξης",
        "end_time_required": "Ορίστε την ώρα λήξης",
        "select_one_date_at_least": "Παρακαλούμε επιλέξτε τουλάχιστον μία ημέρα",
        "fill_all_selected_dates": "Παρακαλώ συμπληρώστε όλα τα πεδία για τις επιλεγμένες ημερομηνίες",
        "job_cant_start_early": "Η εργασία δεν μπορεί να ξεκινά πιο σύντομα από 24 ώρες",
        "must_include_day_of_start_date": "Η ημέρα έναρξης πρέπει να συμπεριλαμβάνεται",
        "must_include_day_of_end_date": "Η ημέρα λήξης πρέπει να συμπεριλαμβάνεται",
        "description_required": "Ορίστε μια περιγραφή",
        "description_too_long": "Η περιγραφή είναι πολύ μεγάλη, παρακαλούμε κάντε την πιο σύντομη",
        "experience_required": "Επιλέξτε το επίπεδο εμπειρίας",
        "dresscode_required": "Επιλέξτε ενδυματολογικό κώδικα",
        "languages_required": "Επιλέξτε γλώσσες ή αφαιρέστε το φίλτρο",
        "documents_required": "Επιλέξτε αρχεία ή αφαιρέστε το φίλτρο",
        "interview_required": "Επιλέξτε ερωτήσεις ή αφαιρέστε το φίλτρο",
        "manual_selection_required": "Επιλέξτε δυνατότητα χειροκίνητης επιλογής ή αφαιρέστε το φίλτρο",
        "accreditation_required": "Επιλέξτε διαπίστευση ή αφαιρέστε το φίλτρο",
        "staff_amount_title": "Πόσα άτομα προσωπικό χρειάζεστε;",
        "staff_amount_subtitle": "Επιλέξτε από το + τον αριθμό εργαζομένων ή πληκτρολογήστε τον",
        "location_title": "Σε ποια περιοχή χρειάζεστε το προσωπικό;",
        "location_subtitle": "Επιλέξτε την τοποθεσία της επιχείρησής σας ή τον χώρο που χρειάζεστε προσωπικό.",
        "location_add_new": "Προσθέστε Νέα Τοποθεσία",
        "location_add": "Προσθέστε Τοποθεσία",
        "dates_title": "Πότε χρειάζεστε το προσωπικό;",
        "dates_subtitle": "Επιλέξτε την ημερομηνία/ημερομηνίες που τους χρειάζεστε.",
        "dates_open_ended": "Ανοιχτή (χωρίς ημ/νία λήξης)",
        "schedule_title": "Θα δουλεύουν τις ίδιες μέρες κάθε εβδομάδα;",
        "schedule_subtitle": "Για παράδειγμα, Δευτέρα με Παρασκευή κάθε εβδομάδα.",
        "schedule_i_have_same_weekly": "Έχω το ίδιο πρόγραμμα κάθε εβδομάδα",
        "schedule_schedule_weekly": "Προγραμματισμός σε εβδομαδιαία βάση",
        "schedule_i_need_schedule_daily": "Χρειάζεται να προγραμματίσω σε καθημερινή βάση",
        "schedule_schedule_daily": "Προγραμματισμός σε ημερήσια βάση",
        "time_title": "Ποιο είναι το ωριαίο πρόγραμμα της θέσης;",
        "time_subtitle": "Επιλέξτε ποιες ημέρες θέλετε να εργάζεται το προσωπικό",
        "time_select_dates_schedule": "Επιλέξτε πρώτα ημερομηνίες και τύπο προγράμματος",
        "time_days_selected": "{{count}} ημέρες επιλέχθηκαν",
        "time_dates_selected": "{{count}} ημερομηνίες επιλέχθηκαν",
        "requirements_title": "Έχετε συγκεκριμένες απαιτήσεις;",
        "requirements_subtitle": "Ζητήστε μας να κάνουμε επαλήθευση δεξιοτήτων στους υποψήφιους για την θέση",
        "requirements_add_new": "Προσθήκη Νέας Δεξιότητας",
        "requirements_create": "Δημιουργήστε Επαγγελματική Δεξιότητα",
        "requirement_requirement": "Δεξιότητα",
        "requirement_add_requirement": "Προσθέστε Δεξιότητα",
        "description_title": "Με τι θα απασχοληθούν;",
        "description_subtitle": "Προσθέστε μια περιγραφή της θέσης (Περιγράψτε τα καθήκοντα και τις ευθύνες που θα αναλάβουν)",
        "description_placeholder": "Περιγράψτε τις λεπτομέρειες της θέσης",
        "experience_title": "Πόσο έμπειρο χρειάζεται να είναι το προσωπικό;",
        "experience_subtitle": "Επιλέξτε το επίπεδο εμπειρίας που θέλετε να έχει το προσωπικό",
        "experience_student": "Φοιτητής/τρια",
        "experience_beginner": "Αρχάριος",
        "experience_junior": "Junior - Με μικρή εμπειρία",
        "experience_middle": "Mid-Senior - Αρκετή εμπειρία",
        "experience_senior": "Senior - Πολύ εμπειρία",
        "experience_hint": "*Οσο μεγαλύτερη είναι η εμπειρία, τόσο αυξάνεται το ωρομίσθιο.",
        "experience_years": "χρόνια",
        "uniform_title": "Έχετε προτίμηση για τον ενδυματολογικό κώδικα;",
        "uniform_subtitle": "Ενημερώστε μας έαν η θέση απαιτεί επίσημο ή καθημερινό ντύσιμο",
        "uniform_dresscode": "Ενδυματολογικός Κώδικας",
        "preferences_title": "Έχετε άλλες επιπλέον προτιμήσεις;",
        "preferences_subtitle": "Σύρετε στην ένδειξη Ν για όποια από τα παρακάτω επιθυμείτε να συμπεριληφθούν.",
        "preferences_languages": "Γλώσσα",
        "preferences_gender": "Φύλο",
        "preferences_documents": "Επιπλέον Έγγραφα",
        "preferences_interview": "Συνέντευξη μέσω Βίντεο",
        "preferences_manual_staff_selection": "Χειροκίνητη Επιλογή Προσωπικού",
        "preferences_accreditation_required": "Απαιτείται Διαπίστευση",
        "preferences_accommodation_offered": "Παροχή δωρεάν διαμονής",
        "accommodation_required": "Απαιτείται Διαμονή",
        "accommodation_yes": "Ναι",
        "accommodation_no": "Όχι",
        "boarding_required": "Απαιτείται Σίτιση",
        "transport_accessibility_required": "Απαιτείται Πρόσβαση σε Μέσα Μεταφοράς",
        "preferences_boarding_offered": "Παροχή σίτισης",
        "accommodation_title": "Παρέχετε σίτιση για αυτή τη θέση εργασίας;",
        "do_you_offer_accommodation": "Παρέχετε δωρεάν διαμονή για αυτή τη θέση εργασίας;",
        "no_boarding": "Δεν παρέχεται σίτιση",
        "half_boarding": "Μερική σίτιση",
        "full_boarding": "Πλήρης σίτιση",
        "languages_title": "Ποια είναι η προτιμώμενη γλώσσα επικοινωνίας μαζί σας;",
        "languages_subtitle": "Επιλέξτε τις γλώσσες που χρειάζεται να γνωρίζει το προσωπικό",
        "gender_title": "Ποια είναι η προτίμησή σας για το φύλο;",
        "gender_subtitle": "Επιλέξτε προτίμηση φύλου, εάν υπάρχει",
        "gender_male": "Άντρας",
        "gender_female": "Γυναίκα",
        "gender_no_preference": "Όχι",
        "documents_title": "Ποια είναι τα απαιτούμενα πρόσθετα έγγραφα;",
        "documents_subtitle": "Επιλέξτε τα απαιτούμενα έγγραφα",
        "manual_selectinon_title": "Θα θέλατε να επιλέξουμε αυτόματα για εσάς τους ιδανικούς Ogrammers για την θέση;",
        "manual_selectinon_subtitle": "Εάν επιλέξετε «όχι» θα χρειαστεί να διαλέξετε Ogrammers χειροκίνητα και δεν θα επωφεληθείτε από τον αλγόριθμο Smart Staff Selection",
        "manual_selectinon_yes": "Ναι",
        "manual_selectinon_no": "Όχι",
        "accreditation_title": "Θα χρειαστεί κάποια διαπίστευση το προσωπικό για την είσοδο στον χώρο/εκδήλωση;",
        "accreditation_subtitle": "Λάβετε υπόψη ότι η διαπίστευση θα επηρεάσει τον χρόνο εκπλήρωσης της παραγγελίας σας",
        "accreditation_yes": "Ναι",
        "accreditation_no": "Όχι",
        "poc_title": "Ποιος θα διαχειριστεί την προσέλευση;",
        "poc_subtitle": "Επιλέξτε το άτομο από την επιχείρησή σας που θα είναι το κύριο πρόσωπο επικοινωνίας για τη θέση",
        "point_of_contact": "Πρόσωπο Επικοινωνίας",
        "add_poc": "Προσθέστε νέο πρόσωπο επικοινωνίας",
        "select_location_first": "Παρακαλούμε, συμπληρώστε πρώτα την τοποθεσία της θέσης",
        "poc_required": "Επιλέξτε επικοινωνία",
        "start_date_must_be_at_least_today": "Η ημερομηνία έναρξης πρέπει να οριστεί το νωρίτερο σήμερα",
        "end_date_must_be_at_least_today": "Η ημερομηνία λήξης πρέπει να οριστεί το νωρίτερο σήμερα",
        "greece_launch_info": "Θα θέλαμε να σας ενημερώσουμε ότι η δημιουργία θέσης εργασίας μπορεί να γίνει μετά τις 1 Ιουνίου. Επεξεργαζόμαστε τα αιτήματά σας εντός 72 ωρών. Τυχόν αιτήματα που υποβλήθηκαν πριν από αυτή την ημερομηνία δεν θα υποβληθούν σε επεξεργασία. Ευχαριστούμε για την κατανόησή σας",
        "select_document": "Επιλέξτε αρχεία",
        "junior": "Αρχάριος",
        "midSenior": "Με λίγη εμπειρία",
        "senior": "Έμπειρος",
        "student": "Φοιτητής",
        "beginner": "Αρχάριος",
        "interview_title": "Ποιες είναι οι βασικές θεματικές που θα θέλατε να καλύψετε με τους υποψήφιους;",
        "interview_subtitle": "Οι OG θα υποβάλουν βιντεοσκοπημένες τις ερωτήσεις τους και τις απαντήσεις",
        "interview_new_question": "Προσθήκη Νέας Ερώτησης;",
        "interview_question_form_title": "Προσθήκη Νέας Ερώτησης",
        "interview_question_form_placeholder": "Πληκτρολογήστε την περιγραφή εδώ",
        "interview_question_form_submit": "Υποβολή",
        "interview_question_created": "Η ερώτηση έχει προστεθεί!",
        "start_and_end_date_must_be_selected": "Η Ημερομηνία Έναρξης: {{startDate}} και η Ημερομηνία Λήξης: {{endDate}} πρέπει να είναι επιλεγμένες",
        "what_are_the_shifts_timings": "Πείτε μας για το ωράριο εργασίας σε αυτήν τη θέση:",
        "what_are_job_timings": "Ποιες είναι οι ώρες εργασίας;",
        "select_same_time": "Επιλέξτε ποιες ώρες θέλετε να εργάζεται το προσωπικό καθημερινά",
        "start_and_ends_are_same": "Οι βάρδιες θα ξεκινούν και θα τελειώνουν την ίδια ώρα κάθε μέρα",
        "time_vary": "Οι ώρες βάρδιας διαφέρουν από μέρα σε μέρα",
        "less_than_24h_warning": "Η δουλειά ξεκινά σε λιγότερο από 24 ώρες και μπορεί να μην έχουμε αρκετό χρόνο για να καλύψουμε την θέση."
    },
    "order_requested": {
        "title": "Σας ευχαριστούμε!",
        "subtitle": "Η ομάδα μας έχει λάβει την παραγγελία σας και θα επικοινωνήσουμε μαζί σας σύντομα!",
        "done": "Τέλος",
        "complete_registration": "Ολοκλήρωση εγγραφής",
        "later": "Αργότερα"
    },
    "order_draft": {
        "title": "Δημιουργία Νέας Παραγγελίας",
        "jobs": "Θέσεις Εργασίας",
        "confirm_delete_job": "Είστε σίγουροι ότι θέλετε να διαγράψετε την θέση {{name}} ;",
        "add_another_job": "+ Προσθέστε κι άλλη Θέση Εργασίας",
        "add_another_job_with_plus": "Προσθέστε κι άλλη Θέση Εργασίας",
        "add_first_job": "Προσθέστε την πρώτη Θέση Εργασίας",
        "continue": "Συνέχεια",
        "quantity": "Ποσότητα",
        "days": "Ημέρες",
        "days_amount": "{{count}} ημέρες",
        "location": "Τοποθεσία",
        "what_to_copy": "Ποιες προτιμήσεις από την θέση {{category}} επιθυμείτε να αντιγράψετε;",
        "select_preferences": "Επιλέξτε προτιμήσεις που θέλετε να αντιγραφούν",
        "schedule": "Πρόγραμμα",
        "requirements": "Απαιτήσεις",
        "job_description": "Περιγραφή Εργασίας",
        "seniority_level": "Επίπεδο Εμπειρίας",
        "dresscode": "Ενδυματολογικός Κώδικας",
        "language": "Γλώσσα",
        "gender": "Φύλο",
        "documents": "Επιπλέον Έγγραφα",
        "manual_selection": "Χειροκίνητη Επιλογή Προσωπικού",
        "accreditation_requried": "Απαιτείται Διαπίστευση Εισόδου",
        "accommodation_offered": "Παροχή δωρεάν διαμονής",
        "interview_required": "Συνέντευξη με Βίντεο",
        "boarding_offered": "Παροχή σίτισης",
        "duplicate_job": "Αντιγραφή της Δουλειάς",
        "blank_job": "Κενή Δουλειά",
        "workers": "Προσωπικό",
        "jobs_count": "Αριθμός Θέσεων Εργασίας: {{count}}",
        "public_transport_accessibility": "Προσβασιμότητα σε Μέσα Μαζικής Μεταφοράς",
        "public_transport_accessibility_accessible": "Ναι, είναι κοντά σε στάση λεωφορείου / μετρό",
        "public_transport_accessibility_not_accessible": "Όχι, δεν είναι εύκολα προσβάσιμη με τα μέσα μαζικής μεταφοράς",
        "is_location_accessible_by_transport": "Αυτή η τοποθεσία είναι προσβάσιμη με μέσα μαζικής μεταφοράς;",
        "tell_if_location_is_reachable": "Πείτε μας εάν η τοποθεσία σας είναι προσβάσιμη με λεωφορείο ή μετρό.",
        "pricing": {
            "best_price_4": "Ζητήστε μια κλήση από την ομάδα πωλήσεων",
            "estimated_total": "Εκτιμώμενο σύνολο*",
            "hours": "Ώρες",
            "type": "Είδος",
            "hourly_rate": "Ωρομίσθιο",
            "best_price_1": "Εγγύηση καλύτερης τιμής",
            "best_price_2": "Το μοντέλο τιμολόγησης μας είναι δυναμικά δομημένο, λαμβάνοντας υπόψη την τρέχουσα ζήτηση της αγοράς.",
            "best_price_3": "Αυτή η προσέγγιση μας επιτρέπει να σας προσφέρουμε συνεχώς την καλύτερη αξία και τις καλύτερες υπηρεσίες. Επικοινωνήστε μαζί μας ανά πάσα στιγμή για περισσότερες πληροφορίες."
        },
        "callback_request_sent": "Το αίτημά σας στάλθηκε με επιτυχία",
        "end_date": "Ημερομηνία Λήξης",
        "start_date": "Ημερομηνία Έναρξης",
        "updated": "Ενημερώθηκε",
        "n_hours": "{{hours}} ώρες",
        "edit_job": "Επεξεργασία Θέσης Εργασίας"
    },
    "order_extension": {
        "how_to_extend": "Πώς θέλετε να επεκτείνετε τις ημερομηνίες για τις θέσεις εργασίας;",
        "you_can_extend_order": "Έχετε τη δυνατότητα να επεκτείνετε τις ημερομηνίες για τις θέσεις εργασίας",
        "same_extension_date": "Η νέα ημερομηνία λήξης είναι η ίδια για όλες τις θέσεις εργασίας",
        "different_extension_dates": "Επεκτείνετε τις θέσεις εργασίας για διαφορετικές περιόδους",
        "for_how_long_extend": "Για πόσο καιρό θέλετε να επεκτείνετε τις θέσεις εργασίας σας;",
        "do_not_extend": "Χωρίς Επέκταση",
        "select_date": "Επιλογή Ημερομηνίας",
        "select_start_date": "Επιλέξτε Ημερομηνία Έναρξης",
        "select_end_date": "Επιλέξτε Ημερομηνία Λήξης",
        "extend_order": "Επέκταση Παραγγελίας",
        "schedule_description": "Οι επιπλέον ημέρες που χρειάζεστε να εργαστεί το προσωπικό σας",
        "edit": "Επεξεργασία",
        "done": "Ολοκλήρωση",
        "full_schedule": "ΠΛήρες Πρόγραμμα"
    },
    "order_confirmed": {
        "title": "Η Παραγγελία Επιβεβαιώθηκε",
        "description": "Ευχαριστούμε για την επιβεβαίωση της παραγγελίας σας.",
        "done": "Τέλος"
    },
    "attendance_manager_form": {
        "first_name": "Όνομα",
        "last_name": "Επίθετο",
        "phone": "Τηλέφωνο",
        "email": "Email",
        "job_role": "Ρόλος Εργασίας",
        "save": "Αποθήκευση",
        "edit_attendance_manager": "Επεξεργασία Υπεύθυνου Προσέλευσης",
        "add_attendance_manager": "Προσθήκη Υπεύθυνου Προσέλευσης"
    },
    "attendance_manager_list": {
        "title": "Υπεύθυνοι Προσέλευσης",
        "add_manager": "Προσθέστε Υπεύθυνο",
        "edit": "Επεξεργασία"
    },
    "company_form": {
        "title": "Πληροφορίες Εταιρείας",
        "saved": "Αποθηκεύτηκε",
        "company_name": "Όνομα Εταιρείας",
        "company_name_as_in_license": "Όνομα εταιρείας όπως αναγράφεται στην Εμπορική Άδεια",
        "address": "Διεύθυνση",
        "po_box": "Ταχυδρομικός Κώδικας",
        "vat_certificate": "Πιστοποιητικό Φ.Π.Α.",
        "trade_license": "Εμπορική Άδεια",
        "finance_first_name": "Όνομα Υπεύθυνου Οικονομικών",
        "finance_last_name": "Επίθετο Υπεύθυνου Οικονομικών",
        "finance_phone": "Τηλέφωνο Υπεύθυνου Οικονομικών",
        "finance_email": "Email Υπεύθυνου Οικονομικών",
        "extension": "Εσωτερικός Αριθμός",
        "save": "Αποθήκευση"
    },
    "invoice": {
        "invoice_#": "Τιμολόγιο #{{index}}",
        "export_pdf": "Εξαγωγή (PDF)",
        "exporting": "Γίνεται Εξαγωγή...",
        "report_issue": "Αναφορά προβλήματος",
        "details": "Λεπτομέρειες",
        "invoice_number": "Αριθμός τιμολογίου",
        "date": "Ημερομηνία",
        "due_date": "Ημερομηνία Πληρωμής",
        "status": "Κατάσταση",
        "pending": "Εκκρεμεί",
        "paid": "Πληρωμένο",
        "costs": "Κόστος",
        "subtotal": "Μερικό Σύνολο",
        "transportation": "Μεταφορικά",
        "vat_percent": "Φ.Π.Α. ({{count}}%)",
        "total": "Σύνολο",
        "breakdown": "Ανάλυση",
        "pay_now": "Πληρωμή Τώρα",
        "total_shifts": "Συνολικές Βάρδιες",
        "total_hours": "Συνολικές Ώρες",
        "total_ogs": "Σύνολο Εργαζόμενων OG's",
        "shifts": "Βάρδιες",
        "hours": "Ώρες",
        "amount": "Ποσό",
        "report_issue_modal_title": "Αναφορά προβλήματος",
        "report_issue_message_required": "Απαιτείται μήνυμα",
        "report_issue_message_placeholder": "Περιγράψτε μας το πρόβλημα που αντιμετωπίσατε",
        "report_issue_submit": "Υποβολή",
        "report_issue_cancel": "Ακύρωση",
        "issue_was_reported": "Το πρόβλημα αναφέρθηκε",
        "issue_is_submitting": "Φόρτωση..."
    },
    "invoice_card": {
        "invoice_#": "Αρ. #{{index}}",
        "due_days_ago": "Πληρωμή πριν από {{count}} ημέρες",
        "due_today": "Πληρωμή σήμερα",
        "due_tomorrow": "Πληρωμή αύριο",
        "due_days_ahead": "Πληρωμή σε {{count}} ημέρες"
    },
    "invoice_ogrammer": {
        "og_details": "Πληροφορίες Ogrammer",
        "job": "Θέση Εργασίας",
        "location": "Τοποθεσία",
        "shifts": "Βάρδιες",
        "hours": "Ώρες",
        "amount_due": "Οφειλόμενο ποσό",
        "shift_details": "Λεπτομέρειες βαρδιών",
        "day": "Ημέρα",
        "approved_by": "Εγκρίθηκε από"
    },
    "profile_form": {
        "title": "Επεξεργασία προφίλ",
        "first_name_required": "Το όνομα είναι υποχρεωτικό",
        "last_name_required": "Το επίθετο είναι υποχρεωτικό",
        "phone_required": "Το τηλέφωνο είναι υποχρεωτικό",
        "email_required": "Το email είναι υποχρεωτικό",
        "upload_photo": "Ανεβάστε Φωτογραφία",
        "private": "Ιδιώτης",
        "corporate": "Εταιρεία",
        "register_corporate": "Εγγραφή Επιχείρησης",
        "first_name": "Όνομα",
        "last_name": "Επίθετο",
        "phone": "Τηλέφωνο",
        "email": "Email",
        "save": "Αποθήκευση",
        "cancel": "Ακύρωση",
        "sign_out": "Αποσύνδεση",
        "agree_to_emails": "Συμφώνω να λαμβάνω ειδοποιήσεις για λόγους marketing από την Ogram"
    },
    "rating_request_list": {
        "title": "Αιτήματα Αξιολόγησης",
        "empty_list": "Δεν υπάρχουν εκκρεμή αιτήματα"
    },
    "rating_request_panel": {
        "title": "Αιτήματα Αξιολόγησης",
        "action": "Βαθμολογήστε Τώρα"
    },
    "job_rating": {
        "title": "Αξιολόγηση Θέσης",
        "how_was_experience_question": "Πώς θα αξιολογούσατε την συνολική σας εμπειρία;",
        "please_rate_team": "Παρακαλούμε βαθμολογήστε το σύνολο της ομάδας",
        "tell_us_about_ogs": "Πείτε μας περισσότερα για τους Ogrammers",
        "tell_us_which_stood_out": "Πείτε μας ποιοι ξεχώρισαν και ποιοι έχουν περιθώρια βελτίωσης",
        "confirm": "Επιβεβαίωση",
        "comment": "Σχόλιο",
        "provide_overall_rating": "Δώστε μια συνολική βαθμολογία",
        "provide_og_rating": "Βαθμολογήστε τον Οgrammer",
        "provide_ogrammers_rating": "Βαθμολογήστε τους Οgrammers",
        "thank_you_for_submitting": "Σας ευχαριστούμε για την υποβολή της αξιολόγησης!"
    },
    "shifts_attendance_list": {
        "title": "Ανασκόπηση Παρουσιών",
        "empty_list": "Δεν υπάρχουν εκκρεμείς αναφορές παρουσίας",
        "review_now": "Aνασκόπηση Τώρα"
    },
    "shifts_attendance_details": {
        "attended_question": "Παρεβρέθηκε?",
        "attended": "Παρεβρέθηκε",
        "not_attended": "Δεν παρεβρέθηκε",
        "did_not": "Δεν παρεβρέθηκε",
        "confirm_amount": "({{count}}) επιβεβαιωμένοι"
    },
    "shift_attendance_common": {
        "are_you_sure_og_worked_n_hours": "Είστε σίγουροι ότι ο/η {{name}} εργάστηκε για {{hours}} ώρες; Είναι {{difference}} ώρες λιγότερες από αυτές που είχατε κλείσει.",
        "no_show": "Δεν Εμφανίστηκε",
        "limited_time_to_confirm": "Περιορισμένος χρόνος για επιβεβαίωση",
        "you_have_n_to_review": "Έχετε {{time}} για να αναθεωρήσετε τις παρουσίες για μια συγκεκριμένη ημέρα. Αν δεν μας ενημερώσετε εντός {{time}}, θα θεωρήσουμε ότι το προσωπικό που έκανε check-in έχει επιβεβαιωμένη παρουσία.",
        "i_understand": "Καταλαβαίνω",
        "n_minutes": "{{count}} λεπτά",
        "n_hours": "{{hours}} ώρες",
        "select_reason": "Επιλέξτε Αιτιολογία",
        "update": "Ανανέωση",
        "cancel": "Ακύρωση",
        "hours_left": "{{count}} ώρες απομένουν",
        "check_in": "Άφιξη",
        "check_out": "Αναχώρηση",
        "update_hours": "Ανανέωση {{count}} ωρών",
        "add_hours": "Προσθήκη {{count}} ωρών",
        "shift_confirmed": "Η βάρδια επιβεβαιώθηκε",
        "thanks_for_confirming": "Ευχαριστούμε για την επιβεβαίωση της παρουσίας των Ogrammers.",
        "done": "Ολοκληρώθηκε",
        "day_off": "Ρεπό",
        "total_hours": "Συνολικές Ώρες",
        "shift_duration_too_long": "Η διάρκεια της βάρδιας δεν πρέπει να υπερβαίνει τις 12 ώρες"
    },
    "user_management": {
        "users": "Χρήστες",
        "add_new_user": "Προσθέστε Νέο Χρήστη",
        "invite_user": "Προσκαλέστε Χρήστη",
        "confirm_deletion_question": "Είστε σίγουροι ότι θέλετε να διαγράψετε αυτή την επαφή;",
        "enter_first_name": "Προσθέστε Όνομα",
        "enter_last_name": "Προσθέστε Επίθετο",
        "enter_phone": "Προσθέστε Τηλέφωνο",
        "enter_email": "Προσθέστε email",
        "choose_role": "Επιλέξτε ρόλο",
        "choose_location": "Επιλέξτε τοποθεσία",
        "first_name": "Όνομα",
        "last_name": "Επίθετο",
        "phone": "Τηλέφωνο",
        "email": "Email",
        "invite_user_exclamation": "Προσκαλέστε τον Χρήστη τώρα!",
        "job_role": "Ρόλος Θέσης",
        "location": "Τοποθεσία",
        "save": "Αποθήκευση",
        "delete": "Διαγραφή",
        "cancel": "Ακύρωση",
        "you_have_n_users": "Έχετε {{count}} χρήστες",
        "add_user": "Προσθέστε Νέο Χρήστη",
        "or": "ή",
        "invite_existing_user": "Προσκαλέστε υπάρχοντα χρήστη",
        "invitation_confirmed": "Η πρόσκληση επιβεβαιώθηκε!",
        "invitation_alert_text": "Η πρόσκληση στάλθηκε με επιτυχία. Ο χρήστης θα εργαστεί στην εταιρεία σας αφού αποδεχτεί την πρόσκληση.",
        "users_tab_active_users": "Χρήστες",
        "users_tab_invitations": "Προσκλήσεις",
        "role_admin": "Διαχειριστής",
        "role_owner": "Ιδιοκτήτης",
        "role_manager": "Διευθυντής",
        "role_finance": "Οικονομικός"
    },
    "onboarding": {
        "knowledge_center": "Κέντρο Γνώσης",
        "create_password": "Δημιουργία Κωδικού",
        "register_corporate": "Εγγραφή Εταιρείας",
        "click_here_to_close": "Κλείσιμο"
    },
    "lead_widget": {
        "looking_for_staff": "Ψάχνετε\nπροσωπικό προσωρινής απασχόλησης;",
        "hire_now": "Προσλάβετε Τώρα"
    },
    "uncompleted_actions_carousel": {
        "looking_for_staff": "Ψάχνετε προσωπικό \nπροσωρινής απασχόλησης;",
        "hire_now": "Προσλάβετε Τώρα",
        "edit_company": "Προσθέστε τα στοιχεία της εταιρείας και \nαποκτήστε πρόσβαση στις ευέλικτες πληρωμές",
        "start_edit_company": "Ξεκινήστε Τώρα",
        "add_poc": "Ορίστε ρόλους \nγια τα μέλη της ομάδας",
        "start_add_poc": "Ξεκινήστε Τώρα",
        "dismiss_add_poc": "Κλείσιμο",
        "error_failed_to_load": "Αποτυχία φόρτωσης carousel",
        "connect": "Σύνδεση",
        "connect_your_bank_account": "Συνδέστε τον λογαριασμό με την Τράπεζά σας",
        "become_affiliate": "Προσκαλέστε το προσωπικό σας να συμμετάσχει στην Ogram"
    },
    "not_authorized_error": {
        "title": "Δεν επιτρέπεται η πρόσβαση",
        "description": "Δεν έχετε άδεια πρόσβασης στη συγκεκριμένη σελίδα...",
        "sign_in": "Σύνδεση"
    },
    "page_not_found": {
        "title": "Η σελίδα δεν βρέθηκε!",
        "description": "Η σελίδα που ψάχνετε δεν υπάρχει...",
        "go_back": "Πίσω"
    },
    "permission_error": {
        "title": "Δεν έχετε άδεια να δείτε αυτή τη σελίδα",
        "back": "Πίσω"
    },
    "too_many_attempts": {
        "title": "Πάρα πολλές προσπάθειες!",
        "description": "Παρακαλούμε δοκιμάστε πάλι σε λίγο.",
        "ok": "Oκ"
    },
    "notifications": {
        "title": "Ειδοποιήσεις",
        "load_more": "Φόρτωση περισσότερων",
        "no_more_notifications": "Δεν υπάρχουν άλλες ειδοποιήσεις",
        "no_notifications_yet": "Δεν υπάρχουν ειδοποιήσεις ακόμα",
        "error_failed_to_load_notifications": "Αποτυχία φόρτωσης ειδοποιήσεων. Προσπαθήστε αργότερα"
    },
    "home_onboarding": {
        "title": "Αρχική",
        "intro_title": "Καλωσήρθατε στην Ogram!",
        "intro_description": "Η έξυπνη πλατφόρμα που χρησιμοποιούν επιχειρήσεις και ιδιώτες για να καλύψουν άμεσα και εύκολα τις ανάγκες τους για προσωπικό προσωρινής απασχόλησης.",
        "intro_footer": "Κάθε φορά που χρησιμοποιείτε μια λειτουργία για πρώτη φορά, θα εμφανίζεται ένα μήνυμα όπου θα σας καθοδηγεί.",
        "hire_title": "Προσλάβετε Προσωπικό Προσωρινής Απασχόλησης",
        "hire": "Η λειτουργία “Προσλάβετε Τώρα” σας επιτρέπει να βρείτε προσωπικό προσωρινής απασχόλησης. Με αυτόν τον τρόπο μας ενημερώνετε για το τι προσωπικό χρειάζεστε, πού και πότε το χρειάζεστε.",
        "attendance_title": "Μετρητής Ανασκόπησης Παρουσιών",
        "attendance": "Εδώ ελέγχετε και υπογράφετε τις ψηφιακές αναφορές παρουσίας. Ο αριθμός στο πλαίσιο υποδεικνύει τις εκκρεμείς αναφορές για έλεγχο.",
        "orders_title": "Μετρητής Επιβεβαίωσης Παραγγελιών",
        "orders": "Όποτε μια παραγγελία προσωπικού απαιτεί χειροκίνητη τιμολόγηση, χρειαζόμαστε την τελική σας επιβεβαίωση πριν μπορέσουμε να αναθέσουμε το προσωπικό. Εδώ μπορείτε να αποκτήσετε πρόσβαση σε όλες τις εκκρεμείς παραγγελίες.",
        "ratings_title": "Μετρητής Αιτημάτων Αξιολόγησης",
        "ratings": "Η γνώμη σας είναι πολύ σημαντική για εμάς και την χρησιμοποιούμε στην προηγμένη τεχνολογία μας για να βελτιώνουμε συνεχώς τις υπηρεσίες μας. Εδώ μπορείτε να αξιολογήσετε την υπηρεσία.",
        "applicants_review_title": "Μετρητής Υποψηφίων Εργαζόμενων",
        "applicants_review": "Η πλατφόρμα επιλέγει και αναθέτει αυτόματα το προσωπικό. Μπορείτε επίσης να επιλέξετε το προσωπικό με βάση την εμπειρία του. Εδώ μπορείτε να αναθεωρήσετε και να επιλέξετε υποψηφίους.",
        "outro": "Είστε έτοιμοι να ξεκινήσετε; \nΜπορείτε πάντα να έχετε πρόσβαση στο 'Κέντρο Γνώσης' από το μενού της αρχικής οθόνης ή κάνοντας κλικ στο '?', όποτε χρειάζεστε καθοδήγηση."
    },
    "all_jobs_onboarding": {
        "title": "Όλες οι Θέσεις Εργασίας",
        "jobs_list_title": "Λίστα Θέσεων Εργασίας",
        "jobs_list": "Στην ενότητα “Όλες οι Δουλειές” θα βρείτε μια λίστα των θέσεων εργασίας σας, χωρισμένη ανά ημέρα. Κάνοντας κλικ σε μια θέση εργασίας, μπορείτε να δείτε τη λίστα των Ogrammers που θα εργαστούν σε αυτήν.",
        "job_details_title": "Πληροφορίες Θέσης εργασίας",
        "job_details": "Στη σελίδα μιας θέσης εργασίας, μπορείτε να καταγράψετε την άφιξη του προσωπικού, να επεκτείνετε ή να ακυρώσετε βάρδιες. Η προσθήκη ενός Ogrammer στα αγαπημένα δίνει μεγαλύτερες πιθανότητες στην επιλογή τους την επόμενη φορά.",
        "change_schedule_title": "Αλλαγή Προγράμματος",
        "change_schedule": "Μπορείτε να προσαρμόσετε το πρόγραμμα των βαρδιών σας. Η ενημέρωση του προγράμματος είναι πολύ σημαντική, ώστε οι Ogrammers να μπορούν να χρησιμοποιούν τις εφαρμογές τους για να καταγράφουν την άφιξή τους.",
        "bulk_schedule_change_title": "Μαζική Αλλαγή Προγράμματος",
        "bulk_schedule_change": "Μπορείτε να αλλάξετε το πρόγραμμα για μια ή περισσότερες μέρες κάθε φορά. Η διάρκεια της βάρδιας δεν μπορεί να μειωθεί.",
        "cancel_shift_title": "Ακύρωση Βάρδιας",
        "cancel_shift": "Μπορείτε να ακυρώσετε τη βάρδια ενός μεμονωμένου Ogrammer, καθώς και να τους αποκλείσετε από την υποβολή αιτήσεων για όλες τις μελλοντικές βάρδιες."
    },
    "attendance_review_onboarding": {
        "title": "Έλεγχος Προσέλευσης",
        "jobs_pending_review_title": "Θέσεις εργασίας που αναμένουν αίτηση",
        "jobs_pending_review": "Η ενότητα \"Ανασκόπηση Παρουσιών\" σας επιτρέπει να προσαρμόζετε και να επιβεβαιώνετε τις παρουσίες των Ogrammers σε καθημερινή βάση. Θα χρεώνεστε ανάλογα με τον καταγεγραμμένο χρόνο.",
        "attendance_details_title": "Λεπτομέρειες Προσέλευσης",
        "attendance_details": "Η σελίδα μιας θέσης εργασίας δείχνει μια λίστα με τις καταγεγραμμένες ώρες των εργαζόμενων Ogrammers. Μπορείτε να διορθώσετε τον χρόνο κάνοντας κλικ πάνω του.",
        "attendance_status_title": "Αλλαγή Κατάστασης Προσέλευσης",
        "attendance_status": "Μπορείτε επίσης να διορθώσετε την κατάσταση παρουσίας ενός Ogrammer εάν σημειώσατε λανθασμένα ότι δεν παρευρέθηκε.",
        "attendance_time_title": "Αλλαγή Ώρας Προσέλευσης",
        "attendance_time": "Θα σας ζητηθεί να εισαγάγετε τον σωστό χρόνο παρουσίας, ο οποίος θα ρυθμιστεί στις προγραμματισμένες ώρες από προεπιλογή.",
        "not_attended_reason_title": "Αλλαγή Λόγου Απουσίας",
        "not_attended_reason": "Αν ένας Ogrammer δεν έχει παρευρεθεί, μπορείτε να αλλάξετε την αιτία· είτε ήταν ρεπό είτε άδεια ασθενείας.",
        "confirmation_time_title": "Επιβεβαίωση ώρας",
        "confirmation_time": "Είναι πολύ σημαντικό να επιβεβαιώνονται τακτικά οι παρουσίες των Ogrammers, καθώς θα οριστικοποιούνται από το σύστημα αυτόματα εντός 72 ωρών. Σε περίπτωση που δεν επιβεβαιωθούν, η χρέωση θα είναι ανάλογη."
    },
    "order_confirmation_onboarding": {
        "title": "Παραγγελίες",
        "pending_confirmation_title": "Παραγγελίες που αναμένουν επιβεβαίωση",
        "pending_confirmation": "Η ενότητα \"Παραγγελίες\" περιλαμβάνει λίστα με τις προκρατημένες θέσεις εργασίας σου, για τις οποίες εκκρεμεί ο έλεγχος και η επιβεβαίωσή τους.",
        "order_confirmation_title": "Επιβεβαίωση Παραγγελιών",
        "order_confirmation": "Στη σελίδα Παραγγελίες σε εκκρεμότητα, παρακαλούμε ελέγξτε προσεκτικά τις λεπτομέρειες της παραγγελίας και τις τελικές χρεώσεις. Το προσωπικό θα ανατεθεί μόλις επιβεβαιωθεί η παραγγελία.",
        "order_amend_title": "Τροποποίηση Παραγγελίας",
        "order_amend": "Αν αλλάξουν οι απαιτήσεις σας ή έχετε ανησυχίες για τον προϋπολογισμό, παρακαλούμε μη διστάσετε να τροποποιήσετε την παραγγελία."
    },
    "order_creation_onboarding": {
        "title": "Δημιουργία Παραγγελίας",
        "select_designation_title": "Διαλέξτε Ειδικότητα",
        "select_designation": "Η διαδικασία δημιουργίας παραγγελίας ξεκινάει με την επιλογή ειδικότητας για την πρώτη θέση εργασίας. Μπορούν να προστεθούν επιπλέον ειδικότητες μόλις δημιουργηθεί η πρώτη θέση.",
        "job_details_title": "Λεπτομέρειες Θέσης Εργασίας",
        "job_details": "Επιλέξτε την θέση που θέλετε να καλύψετε και στην συνέχεια ενημερώστε τον αριθμό των υπαλλήλων που χρειάζεστε στο πεδίο “ποσότητα”.",
        "job_duration_title": "Διάρκεια Θέσης Εργασίας",
        "job_duration": "Αν η δουλειά είναι επαναλαμβανόμενη και δεν έχει συγκεκριμένη ημερομηνία λήξης, μπορείτε να σύρετε τον διακόπτη για να αφαιρέσετε την ημερομηνία λήξης και να κρατήσετε τη δουλειά ανοιχτή.",
        "scheduling_options_title": "Επιλογές Προγραμματισμού",
        "scheduling_options": "Εάν το πρόγραμμα της θέσης επαναλαμβάνεται κάθε εβδομάδα, επιλέξτε την 'Εβδομαδιαία' επιλογή και ορίστε τις ώρες εργασίας για την κάθε ημέρα. Διαφορετικά, μπορείτε να φτιάξετε το πρόγραμμα σε καθημερινή βάση",
        "job_requirements_title": "Απαιτήσεις Θέσης",
        "job_requirements": "Μπορείτε να επιλέξετε δεξιότητες από την προκαθορισμένη λίστα ή να προσθέσετε συγκεκριμένες δεξιότητες/καθήκοντα που απαιτούνται για τη θέση.",
        "shifts_breakdown_title": "Ανάλυση Βαρδιών",
        "shifts_breakdown": "Μόλις καθορίσετε τις λεπτομέρειες της πρώτης δουλειάς, δημιουργείται μια πρόχειρη παραγγελία. Μπορείτε να προσθέσετε περισσότερες δουλειές/θέσεις στην παραγγελία πατώντας το κουμπί “Προσθήκη Άλλης Δουλειάς”.",
        "duplicate_job_title": "Αντιγραφή Θέσης Εργασίας",
        "duplicate_job": "Εάν επιθυμείτε να δημιουργήσετε και άλλες θέσεις με ίδιες ή παρόμοιες απαιτήσεις, μπορείτε να κάνετε κλικ στο κουμπί αντιγραφής θέσης εργασίας και να επιλέξετε ποιες λεπτομέρειες θέλετε να αντιγράψετε.",
        "send_for_review_title": "Αποστολή για Έλεγχο",
        "send_for_review": "Πατώντας το κουμπί 'Αποστολή για Έλεγχο', θα σταλεί το αίτημά σας στην ομάδα πωλήσεών μας για έλεγχο. Θα προχωρήσουμε σε τιμολόγηση της κράτησης και θα σας ειδοποιήσουμε για την τελική επιβεβαίωση."
    },
    "applicants_review_onboarding": {
        "title": "Αξιολόγηση Αιτούντων",
        "pending_applicant_review_title": "Δουλειές σε εκκρεμότητα ελέγχου υποψηφίων",
        "pending_applicant_review": "Η ενότητα \"Αξιολόγηση Αιτούντων\" περιλαμβάνει λίστα με θέσεις εργασίας στις οποίες εκκρεμεί η αξιολόγηση των υποψηφίων. Μόλις καλυφθεί μια θέση, θα αφαιρεθεί από τη λίστα.",
        "applicant_list_title": "Λίστα Υποψηφίων",
        "applicant_list": "Η σελίδα μιας θέσης εργασίας δείχνει μια λίστα με τους Ogrammers που έχουν υποβάλει αίτηση. Μπορείτε να τους αποδεχτείτε, να τους απορρίψετε απευθείας ή να εξετάσετε περαιτέρω τα στοιχεία του προφίλ τους.",
        "applicant_details_title": "Λεπτομέρειες Υποψηφίου",
        "applicant_details": "Η σελίδα λεπτομερειών ενός Ogrammer εμφανίζει τη βαθμολογία του, τις κορυφαίες δεξιότητές του, το επίπεδό εμπειρίας του, το βιογραφικό σημείωμά του, την εργασιακή του εμπειρία και την εκπαίδευσή του."
    },
    "locations_onboarding": {
        "title": "Τοποθεσίες",
        "location_list_title": "Λίστα Τοποθεσιών",
        "location_list": "Η ενότητα \"Τοποθεσίες\" περιλαμβάνει μια λίστα με όλες τις τοποθεσίες των υποκαταστημάτων σας.",
        "location_details_title": "Λεπτομέρειες Τοποθεσίας",
        "location_details": "Παρακαλούμε βεβαιωθείτε ότι η τοποθεσία σας στον χάρτη είναι ακριβής. Οι Ogrammers δεν θα μπορούν να δηλώσουν την άφιξή τους εκτός του επιλεγμένου σημείου στον χάρτη."
    },
    "users_onboarding": {
        "title": "Χρήστες",
        "user_list_title": "Λίστα Χρηστών",
        "user_list": "Η ενότητα \"Χρήστες\" σας επιτρέπει να ελέγχετε ποιοι έχουν πρόσβαση στην εταιρεία σας. Μπορείτε να δημιουργήσετε νέους χρήστες ή να προσκαλέσετε υπάρχοντες.",
        "user_details_title": "Λεπτομέρειες Χρηστών",
        "user_details": "Μπορείτε να αναθέσετε έναν από τους ακόλουθους ρόλους: Διαχειριστής, Υπεύθυνος Παρουσιών, Οικονομικός. Οι ρόλοι μπορούν να ανατεθούν διαφορετικά σε κάθε τοποθεσία."
    },
    "rating_requests_onboarding": {
        "title": "Αιτήματα Αξιολόγησης",
        "pending_feedback_title": "Θέσεις Εργασίας σε εκκρεμότητα αξιολόγησης",
        "pending_feedback": "Η ενότητα \"Αιτήματα Αξιολόγησης\" περιέχει μια λίστα με θέσεις εργασίας που απαιτούν την ανατροφοδότησή σας. Μετά την ολοκλήρωση μιας θέσης εργασίας, θα θέλαμε να αξιολογήσετε την εμπειρία σας.",
        "rating_details_title": "Λεπτομέρειες Βαθμολογίας της Θέσης",
        "rating_details": "Μπορείτε να αξιολογήσετε τη συνολική σας εμπειρία από τη θέση εργασίας, καθώς και να αξιολογήσετε μεμονωμένους Ogrammers. Είστε ελεύθεροι να επαινέσετε, να επισημάνετε προβλήματα και να αφήσετε σχόλια."
    },
    "invoices_onboarding": {
        "title": "Τιμολόγια",
        "invoice_list_title": "Λίστα Τιμολογίων",
        "invoice_list": "Η ενότητα “Τιμολόγια” περιέχει μια λίστα με τα τρέχοντα και τα προηγούμενα τιμολόγιά σας. Εάν κάποιο τιμολόγιο είναι σε κατάσταση “Εκκρεμεί”, αυτό σημαίνει ότι αναμένουμε πληρωμή από εσάς.",
        "invoice_breakdown_title": "Ανάλυση Τιμολογίου",
        "invoice_breakdown": "Η σελίδα μιας παραγγελίας περιέχει μια σύνοψη καθώς και μια ανάλυση των θέσεων εργασίας. Επεκτείνοντας τις θέσεις εργασίας, θα μπορείτε να βρείτε μια σύνοψη της βάρδιας, διαχωρισμένη ανά Ogrammer.",
        "shifts_breakdown_title": "Ανάλυση Βαρδιών Τιμολογίου",
        "shifts_breakdown": "Κάνοντας κλικ σε έναν Ogrammer, μπορείτε να δείτε πόσες ώρες εργάστηκε κάθε ημέρα.",
        "export_invoice_title": "Έκδοση Τιμολογίου",
        "export_invoice": "Από το Μενού Τιμολογίων, μπορείτε να κατεβάσετε μια ανάλυση τιμολογίου σε μορφή PDF, καθώς και να αναφέρετε τυχόν προβλήματα με ένα τιμολόγιο."
    },
    "duplicate_order": {
        "choose_jobs_main_title": "Πρώτα, επιλέξτε τις επιθυμητές θέσεις εργασίας",
        "choose_jobs_secondary_title": "Επιλέξτε τις θέσεις εργασίας που θέλετε να παρατείνετε",
        "choose_jobs_confirm": "Επιβεβαίωση",
        "duplicate_job_subtitle": "Ελέγξτε τα στοιχεία της θέσης εργασίας",
        "duplicate_order": "Αντιγράψτε την Παραγγελία"
    },
    "payment": {
        "payment": "Πληρωμή",
        "card_payment": "Αποχώρηση",
        "was_successful": "Ευχαριστούμε, η πληρωμή σας έγινε με επιτυχία.",
        "email_was_sent_to": "Σας έχουμε στείλει επιβεβαιωτικό email ",
        "can_go_to_payments_list_page": "Μεταβείτε στη σελίδα πληρωμών για να ελέγξετε την κατάσταση",
        "transaction_id": "ID Συναλλαγής",
        "date": "Ημερομηνία",
        "payment_method": "Τρόπος Πληρωμής",
        "amount": "Ποσό",
        "status": "Κατάσταση",
        "go_to_payments_list_page": "Πηγαίνετε στις Πληρωμές",
        "status_unpaid": "Απλήρωτα",
        "status_processing": "Σε επεξεργασία",
        "status_paid": "Πληρώθηκε",
        "status_cancelled": "Ακυρώθηκε",
        "status_refunded": "Επιστράφηκαν",
        "payment_method_card_title": "Κάρτα",
        "payment_method_card_wide_title": "Πληρωμή με κάρτα",
        "payment_method_card_description": "Καινούργια πιστωτική / χρεωστική κάρτα",
        "payment_method_lean_description": "Μεταφορά απο Τραπεζικό Λογαριασμό",
        "processing_running": "Σε επεξεργασία...",
        "save_card_for_future": "Αποθηκεύστε την κάρτα για μελλοντικές συναλλαγές",
        "all_transactions_are_secured": "Όλες οι συναλλαγές είναι ασφαλείς και κρυπτογραφημένες",
        "details": "Λεπτομέρειες",
        "payment_number": "Αριθμός Πληρωμής",
        "due_date": "Τελική Ημερομηνία Πληρωμής",
        "invoices": "Τιμολόγια",
        "total_payment": "Συνολικό Ποσό Πληρωμής ",
        "pay_now": "Πλήρωσε Τώρα",
        "authorize": "Δώστε Εξουσιοδότηση Για Πληρωμή",
        "pending": "Eκκρεμεί",
        "processing": "Σε επεξεργασία",
        "completed": "Ολοκληρώθηκε",
        "invoice": "Τιμολόγιο",
        "payment_details_title": "Πληρωμή #{{paymentId}}",
        "payments": "Πληρωμές",
        "payment_methods": "Μέθοδοι πληρωμής",
        "select_payment_method": "Επιλέξτε μέθοδο πληρωμής",
        "continue": "Συνεχίστε",
        "pay": "Πληρώστε",
        "payment_completed": "Η πληρωμή ολοκληρώθηκε",
        "payment_processing": "Η πληρωμή επεξεργάζεται",
        "updating_status": "Ενημέρωση Κατάστασης...",
        "empty_pending_list": "Δεν εκκρεμούν πληρωμές",
        "empty_processing_list": "Δεν υπάρχουν πληρωμές σε επεξεργασία",
        "empty_completed_list": "Δεν υπάρχουν ολοκληρωμένες πληρωμές",
        "back_to_order": "Πίσω στις παραγγελίες",
        "overdue_balance": "Εκπρόθεσμο υπόλοιπο",
        "payment_method_wire_transfer_title": "Τραπεζική Μεταφορά",
        "payment_method_wire_transfer_description": "Τραπεζική Μεταφορά",
        "text_copied": "Το κείμενο αντιγράφηκε στο πρόχειρο",
        "checkout": "Ολοκλήρωση Πληρωμής",
        "wire_transfer_details": "Λεπτομέρειες τραπεζικού εμβάσματος",
        "wire_transfer_amount": "Ποσό",
        "wire_transfer_bank_account_name": "Όνομα Λογαριασμού",
        "wire_transfer_bank_account_bank_name": "Όνομα Τράπεζας",
        "wire_transfer_bank_account_number": "Αριθμός Τραπεζικού Λογαριασμού",
        "wire_transfer_bank_account_iban": "IBAN",
        "wire_transfer_bank_account_bank_address": "Διεύθυνση Τράπεζας",
        "wire_transfer_upload_alert_text": "Ανεβάστε το αποδεικτικό τραπεζικής κατάθεσης",
        "next": "Επόμενο",
        "wire_transfer_has_been_canceled": "Η Τραπεζική Μεταφορά έχει ακυρωθεί",
        "wire_transfer_slip_was_uploaded": "Το Αποδεικτικό Πληρωμής έχει καταχωρηθεί",
        "please_upload_slip": "Ανεβάστε το Αποδεικτικό Πληρωμής από την Τράπεζα",
        "bank_slip_info_in_alert": "Στο Αποδεικτικό Πληρωμής πρέπει να φαίνεται το ποσό πληρωμής και η σφραγίδα της Τράπεζας",
        "payment_slip": "Αποδεικτικό Τραπεζικής Μεταφοράς",
        "upload_payment_slip": "Ανέβασμα Αποδεικτικού Πληρωμής",
        "document_selected": "Tα Αρχεία Επιλέχθηκαν",
        "you_can_upload_slip_now_or_later": "Μπορείτε να ανεβάσετε το Αποδεικτικό Πληρωμής τώρα χρησιμοποιώντας το παραπάνω κουμπί, διαφορετικά θα σας το υπενθυμίσουμε αργότερα.",
        "you_can_select_another_payment_method": "Αν θέλετε να επιλέξετε άλλη μέθοδο πληρωμής, ακυρώστε την Τραπεζική Μεταφορά χρησιμοποιώντας αυτόν τον σύνδεσμο.",
        "wire_transfer_cancel_button": "Ακυρώστε την Τραπεζική Μεταφορά και επιλέξτε άλλον τρόπο πληρωμής",
        "wire_transfer_remind_later_button": "Υπενθύμιση Αργότερα",
        "wire_transfer_i_have_sent_money": "Έχω στείλει χρήματα",
        "wire_transfer_slip_uploading": "Φορτώνει...",
        "wire_transfer_slip_under_review": "Το Αποδεικτικό Πληρωμής είναι υπό έλεγχο",
        "wire_transfer_out_team_is_checking_slip": "Η ομάδα μας θα ελέγξει το Αποδεικτικό Πληρωμής σύντομα.",
        "download_slip": "Κατεβάστε το Αποδεικτικό Πληρωμής",
        "card_payment_cancel_button": "Ακυρώστε την πληρωμή με κάρτα και επιλέξτε μια άλλη μέθοδο πληρωμής",
        "card_payment_has_been_canceled": "Η πληρωμή με κάρτα έχει ακυρωθεί",
        "error_failed_to_load_panel": "Αποτυχία φόρτωσης του πίνακα πληρωμών",
        "add_account": "Προσθήκη Λογαριασμού",
        "learn_more_about": "Μάθετε περισσότερα",
        "lean_payment": "Απλοποιημένες Πληρωμές",
        "lean_modal_title_1": "Γιατί να χρησιμοποιήσετε Άμεση Τραπεζική Μεταφορά;",
        "lean_modal_title_2": "Μετακινήστε τα χρήματα με την ασφάλεια της τράπεζάς σας",
        "lean_modal_title_3": "Αυτή η μέθοδος πληρωμής σάς επιτρέπει να πραγματοποιήσετε μια άμεση τραπεζική μεταφορά από τον τραπεζικό λογαριασμό της επιχείρησής σας. Χρειάζεται μόνο να συνδέσετε τον λογαριασμό σας μία φορά και στη συνέχεια θα μπορείτε να τον χρησιμοποιήσετε για τυχόν μελλοντικές πληρωμές.",
        "lean_modal_action_1": "Συνδέστε τον τραπεζικό σας λογαριασμό",
        "lean_modal_action_2": "Πληρώστε άμεσα",
        "lean_modal_title_4": "Οι πληρωμές σας θα είναι πάντα ασφαλείς χάρη στην πολλαπλή ταυτοποίηση, ακριβώς όπως όταν χρησιμοποιείτε τον τραπεζικό σας λογαριασμό.",
        "payment_awaiting_authorization": "Η πληρωμή αναμένει έγκριση",
        "will_be_paid_after_authorize": "Η πληρωμή σας θα καταβληθεί αφού την εξουσιοδοτήσετε.",
        "loading": "Φορτώνει...",
        "connect_lean_account": "Συνδέστε Λογαριασμό Lean",
        "successfully_connected_bank": "Ο τραπεζικός λογαριασμός συνδέθηκε με επιτυχία",
        "payment_has_been_queued": "Η πληρωμή σας έχει δρομολογηθεί",
        "successfully_paid": "H πληρωμή ολοκληρώθηκε επιτυχώς",
        "cannot_pay": "Αποτυχία Πληρωμής",
        "lean": {
            "setup_status_active": "Ενεργή",
            "setup_status_pending": "Eκκρεμής",
            "setup_status_not_connected": "Χωρίς σύνδεση",
            "account_is_being_connected": "Ο λογαριασμός σας συνδέεται. Επιλέξτε άλλο τρόπο πληρωμής ή δοκιμάστε ξανά αργότερα"
        }
    },
    "account_statement": {
        "recent_activities": "Πρόσφατες Δραστηριότητες",
        "account_statement": "Κατάσταση Λογαριασμού",
        "currency": "Ευρώ",
        "from": "Από",
        "to": "Προς",
        "starts_from": "Ξεκινάει από",
        "downloading": "Κατεβαίνει...",
        "download": "Kατεβάστε",
        "invoice": "Τιμολόγιο",
        "credit_note": "Πιστωτικό Σημείωμα",
        "empty_list": "Δεν υπάρχουν δεδομένα για την επιλεγμένη περίοδο"
    },
    "calendar": {
        "month_january": "Ιανουάριος",
        "month_february": "Φεβρουάριος",
        "month_march": "Μάρτιος",
        "month_april": "Απρίλιος",
        "month_may": "Μάιος",
        "month_june": "Ιούνιος",
        "month_july": "Ιούλιος",
        "month_august": "Αύγουστος",
        "month_september": "Σεπτέμβριος",
        "month_october": "Οκτώβριος",
        "month_november": "Νοέμβριος",
        "month_december": "Δεκέμβριος",
        "month": "Μήνας",
        "year": "Έτος",
        "cancel": "Ακύρωση",
        "set": "Καθορισμός Ημερομηνιών",
        "clear": "Διαγραφή Όλων",
        "all_locations": "Όλες οι Τοποθεσίες"
    },
    "payment_item": {
        "status_draft": "Πρόχειρο",
        "status_paid": "Πληρώθηκε",
        "status_unpaid": "Εκκρεμεί",
        "status_processed": "Σε Επεξεργασία",
        "status_voided": "Ακυρωμένο"
    },
    "saved_payment_methods": {
        "method_has_been_added": "Ο Τρόπος Πληρωμής προστέθηκε με επιτυχία",
        "save_card": "Αποθήκευση Κάρτας",
        "saving_progress": "Αποθήκευση...",
        "remove_payment_method": "Αφαίρεση Τρόπου Πληρωμής",
        "method_has_been_deleted": "Ο Τρόπος Πληρωμής αφαιρέθηκε με επιτυχία",
        "payment_methods": "Τρόποι Πληρωμής",
        "no_payment_methods_added": "Δεν έχει οριστεί τρόπος πληρωμής",
        "add_new_card": "Προσθέστε Νέα Κάρτα"
    },
    "error_boundary": {
        "default": {
            "server_error": "Εσωτερικό Σφάλμα Διακομιστή",
            "permission_error": "Άρνηση Πρόσβασης",
            "client_error": "Σφάλμα Πελάτη",
            "not_found_error": "Δεν Βρέθηκε",
            "not_authorized_error": "Ελλιπής Εξουσιοδότηση",
            "connection_error": "Σφάλμα σύνδεσης. Ελέγξτε τη σύνδεσή σας στο Διαδίκτυο",
            "too_many_requests_error": "Πάρα πολλά αιτήματα. Δοκιμάστε ξανά αργότερα",
            "default_error": "Κάτι πήγε λάθος. Προσπαθήστε Ξανά",
            "try_again": "Προσπαθήστε Ξανά"
        },
        "payment": {
            "cannot_fetch_overdue_server_error": "Δεν ήταν δυνατή η ανάκτηση δεδομένων εκπρόθεσμων πληρωμών"
        }
    },
    "pending_orders_panel": {
        "pending_confirmation": "Εκκρεμεί Επιβεβαίωση",
        "n_orders": "{{count}} παραγγελίες",
        "confirm_now": "Επιβεβαιώστε"
    },
    "step_indicator": {
        "step_of": "Βήμα {{currentStepNumber}} από {{stepsCount}}"
    },
    "page_stepper": {
        "next": "Επόμενο",
        "previous": "Πίσω",
        "finish": "Τέλος",
        "of": "από"
    },
    "order_status": {
        "draft": "Πρόχειρο",
        "pending_confirmation": "Εκκρεμεί Επιβεβαίωση",
        "under_review": "Σε Έλεγχο",
        "started": "Έχει Ξεκινήσει",
        "upcoming": "Επερχόμενες"
    },
    "toggle": {
        "yes_short": "Ν",
        "no_short": "O"
    },
    "time": {
        "mon": "Δευ",
        "tue": "Τρι",
        "wed": "Τετ",
        "thu": "Πεμ",
        "fri": "Παρ",
        "sat": "Σαβ",
        "sun": "Κυρ"
    },
    "sp_rating": {
        "novice": "Αρχάριος",
        "competent": "Ικανός",
        "expert": "Ειδήμονας",
        "advanced_beginner": "Με Μικρή Εμπειρία",
        "proficient": "Επιδέξιος"
    },
    "time_picker": {
        "hour": "Ώρα",
        "minute": "Λεπτά",
        "cancel": "Ακύρωση",
        "set": "Επιλογή"
    },
    "become_affiliate": {
        "modal_title": "Γίνετε Συνεργάτης",
        "modal_subtitle": "Προσκαλέστε το προσωπικό σας στην Ogram και κερδίστε χρήματα!",
        "accept_tc": "Όροι και Προϋποθέσεις"
    },
    "common_affiliate": {
        "join_ogram": "Εγγραφείτε στην Ogram μέσω του συνδέσμου μου",
        "my_referrals": "Οι Συστάσεις μου",
        "send_invitation": "Αποστολή Πρόσκλησης",
        "share_link": "Προσκαλέστε την ομάδα σας στο App της Ogram και ξεκινήστε να κερδίζετε χρήματα!",
        "copy_and_send_link": "Ή αντιγράψτε τον παρακάτω σύνδεσμο και στείλτε τον",
        "tc_1": "Αυτοί οι όροι δεν σχετίζονται άμεσα με το πρόγραμμα συστάσεων, αλλά είναι αρκετά συναφείς για να τους συμπεριλάβουμε εδώ (μερικοί ορίζουν άλλες διακριτές κατηγορίες παραπομπής μάρκετινγκ)",
        "tc_2": "Ο ορισμός αυτών των ορολογιών βοηθά στην καλύτερη οριοθέτηση του τι είναι και τι δεν είναι πρόγραμμα συστάσεων.",
        "section_title": "ΣΥΝΕΡΓΑΤΗΣ",
        "dashboard": "Πίνακας Ελέγχου",
        "referrals": "Συστάσεις",
        "earnings": "Κέρδη",
        "designation": "Ειδικότητα",
        "payout": "Αποπληρωμή",
        "due_date": "Ημερομηνία Πληρωμής",
        "join_date": "Ημερομηνία Έναρξης",
        "this_month": "Αυτόν τον Μήνα",
        "invite_more_staff": "Προσκαλέστε επιπλέον προσωπικό",
        "margin_split": "Κατανομή Περιθωρίου",
        "invite_your_staff": "Προσκαλέστε το προσωπικό σας",
        "invite_staff_more_details": "Προσκαλέστε τους συναδέλφους σας στην Ogram και κερδίστε χρήματα!",
        "invite_now": "Προσκαλέστε Τώρα",
        "earning_history": "Ιστορικό Κερδών",
        "total_earnings": "Συνολικά Κέρδη",
        "status_profile_created": "Το προφίλ δημιουργήθηκε",
        "status_activated": "Ενεργοποιημένο",
        "status_booked_shifts": "Κλεισμένες Βάρδιες",
        "status_shifts_done": "Ολοκληρωμένες Βάρδιες",
        "status_unknown": "Άγνωστο",
        "referrals_list": "Λίστα Συστάσεων"
    },
    "affiliate_instructions": {
        "how_it_works": "Πως δουλεύει",
        "instruction_1_title": "Στείλτε μια Πρόσκληση",
        "instruction_1_subtitle": "Απλά μοιραστείτε τον σύνδεσμό σας",
        "instruction_2_title": "Εκείνοι γράφονται στην Ogram",
        "instruction_2_subtitle": "Και ολοκληρώνουν τις βάρδιές τους",
        "instruction_3_title": "Εσείς Ξεκινάτε Να Κερδίζετε!",
        "instruction_3_subtitle": "Λάβετε προμήθεια για τη δουλειά τους",
        "instruction_4_title": "Παρακολουθήστε το Προσωπικό σας",
        "instruction_4_subtitle": "Χρησιμοποιώντας τον πίνακα ελέγχου συνεργατών μας"
    },
    "qr_scan": {
        "invalid_qr_code_provided": "Μη έγκυρο QR Code. Παρακαλούμε, δώστε αυτό που σας έδωσε ένας Ogrammer",
        "camera_permission_required": "Απαιτείται Πρόσβαση στην Κάμερα",
        "access_denied": "Δεν επιτράπηκε πρόσβαση στην κάμερα.",
        "enable_permission": "Για να σαρώσετε το QR code, παρακαλούμε ενεργοποιήστε την άδεια πρόσβασης στην κάμερα ή, εναλλακτικά, χρησιμοποιήστε την κάμερα του κινητού σας.",
        "instruction_title": "Ποια είναι η διαδικασία;",
        "step_1": "Βήμα Πρώτο",
        "to_scan": "Σκανάρετε",
        "step_1_text": "Σκανάρετε τον κωδικό που θα σας δώσει ο OG.",
        "step_2": "Βήμα Δεύτερο",
        "check_in_or_out": "Δηλώστε Άφιξη και Αποχώρηση",
        "step_2_text": "Καταγράψτε την άφιξη ή την αποχώρησή τους και δείτε τις λεπτομέρειες της θέσης εργασίας.",
        "sp_id_form_title": "Αν το QR code δεν λειτουργεί, παρακαλούμε εισάγετε το OG ID",
        "sp_id_label": "Εισάγετε OG ID",
        "scan_qr": "Σκανάρετε QR",
        "sp_cant_be_checked_in_or_out": "Δεν μπορεί να δηλωθεί ώρα άφιξης και αποχώρησης για αυτόν τον OG",
        "qr": "QR",
        "scan_next_qr": "Σκανάρετε Άλλο QR",
        "success_modal_text": "Η διαδικασία {{processTitle}} ολοκληρώθηκε με επιτυχία.",
        "carousel_title": "Δηλώστε την άφιξη και την αποχώρηση του προσωπικού χρησιμοποιώντας το QR code",
        "sp_id_form_title_1": "*Δεν δουλεύει το QR code;",
        "sp_id_form_title_2": "Συμπληρώστε το OG ID παρακάτω",
        "camera_missing_title": "Η κάμερά σας δεν είναι συμβατή με τον τρέχοντα browser.",
        "camera_missing_description": " Παρακαλώ δοκιμάστε την κάμερα του κινητού σας αντί γι' αυτό 📸📱",
        "set_default_time": "Ορισμός Προεπιλογής"
    },
    "staff": {
        "past_workers": "Παλιοί Εργαζόμενοι",
        "hours_worked": "Ώρες Εργασίας",
        "n_hours": "{{hoursAmount}} Ώρες",
        "n_shifts": "{{shiftsAmount}} Βάρδιες",
        "last_job": "Προηγούμενη Δουλειά",
        "locations_worked": "Τοποθεσίες που έχει εργαστεί",
        "plus_n_locations": "+ {{locationsAmount}} Τοποθεσίες",
        "enter_notes_on_worker": "Κρατήστε σημειώσεις για τον εργαζόμενο",
        "favorite_only": "Μόνο Αγαπημένοι",
        "apply": "Εφαρμογή",
        "reset": "Reset",
        "how_was_the_worker": "Πως ήταν ο OG?",
        "rating_submitted": "Η βαθμολογία καταχωρήθηκε!",
        "search_worker_by_id_or_name": "Αναζητήστε εργαζόμενο με το ID ή το όνομα",
        "worker_name": "Όνομα Εργαζόμενου",
        "designations": "Ειδικότητες",
        "your_rating": "Η βαθμολογία σας",
        "notes": "Notes",
        "no_workers_found": "Δεν βρέθηκαν εργαζόμενοι",
        "note_validation_length": "Οι σημειώσεις δεν μπορούν να ξεπερνούν τους 499 χαρακτήρες",
        "n_more": "{{ amount }} ακόμα",
        "last_designation": "Τελευταία Ειδικότητα",
        "shifts_worked": "Δεδουλευμένες Βάρδιες",
        "last_location": "Τελευταία Τοποθεσία"
    },
    "invited_sps": {
        "quantity_must_not_be_greater": "Ο αριθμός των προσκεκλημένων OGs δεν μπορεί να είναι μεγαλύτερος από τις ανοιχτές θέσεις εργασίας. Αν χρειάζεστε περισσότερους Ogrammers, παρακαλούμε αυξήστε την ποσότητα στο βήμα 1",
        "form_title": "Θα θέλατε να προσκαλέσετε τους προηγούμενους Ogrammers σας;",
        "form_subtitle": "Αυτό το βήμα είναι προαιρετικό. Αν προσκαλέσετε λιγότερους Ogrammers από όσους χρειάζεστε, η Ogram θα καλύψει τις υπόλοιπες θέσεις",
        "select_required_data": "Παρακαλούμε επιλέξτε πρώτα ποσότητα, τοποθεσία, θέση και πρόγραμμα",
        "invited_sps": "Προτιμώμενοι Ogrammers",
        "worked_at_current_location": "Έχει εργαστεί στην τρέχουσα τοποθεσία"
    },
    "pagination": {
        "page": "Page",
        "of": "of"
    }
}
