import { FunctionComponent, useEffect, useMemo, useRef, useState } from 'react'
import { observer } from 'mobx-react'
import { FormikProps } from 'formik'
import { ApiErrorInterface, ApiErrorTypes } from 'ogram-react'
import moment from 'moment/moment'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'

import { JobDraftDetailsProps, UIProps } from './types'
import { useDelayedQuery, useMutation, useQuery, useStore } from '../../utils/mst-hooks'
import { ScheduleType } from '../../models-ui/order/store/job-fields/schedule/types'
import { JobForm, JobSnapshot } from '../../models/job'
import { BusinessLocationForm } from '../../models/location'
import { useRedirect } from '../../services/router'
import { routes } from '../../routes'
import { FormComponentsPossibleParams } from './form-components'
import { getExperienceName } from './utils/get-experience-name'
import { getOptionalFieldsLabels } from './form-data'
import { getGenderName } from './utils/get-gender-name'
import { getPublicTransportAccessibilityName } from './utils/get-public-transport-accessibility-name'
import { DraftJobFields } from '../../models-ui/order/store/job-fields/fields'

/**
 * TODO: - group related queries and move them to separate hooks
 *       - move popupField/setPopupField/setNextField/handleNextPress to job store
 *       - remove all props like `contactFullName` and let subcomponents (they are not implemented yet, see TODO of job-draft-details-form) handle them
 *       - refactor location to be managed via store, because it keeps logic on component-level
 */
export function createJobDraftDetailsFormContainer(UIComponent: FunctionComponent<UIProps>) {
  return observer(
    ({
      orderId,
      activePopupField,
      job,
      onSubmit,
      getFormTitle,
      formActions,
      formSubtitle,
      orderDraftTitle,
      showStepIndicator = false,
      stepNumber,
      isExtension,
    }: JobDraftDetailsProps) => {
      const {
        getProfessions,
        getProfessionName,
        getInterview,
        createOrder,
        createJob,
        createInterview,
        updateInterview,
        deleteInterview,
        updateJob,
        getWorkRequirements,
        getJobDocumentsNames,
        getQuestionNames,
        getLanguagesNames,
        getUniformName,
        getProfession,
        getPointsOfContact,
        getContactFullName,
        getUniforms,
        getWorkCategoryRequirementsNames,
        getOrderSummary,
        getOrderIdSummary,
        getInvitedPastSPsByIds,
        invitedPastSPsList,
        getPastSPs,
        pastSPsList,
      } = useStore().data

      const orderSummary = getOrderIdSummary(String(orderId))
      const { enableQuery: getOrderSummaryQuery } = useDelayedQuery(
        () => getOrderSummary({ orderId: String(orderId) }),
        {
          filterError: [ApiErrorTypes.ClientError],
        },
      )

      const { enableQuery: requestInterview } = useDelayedQuery(
        () => getInterview({ jobId: job.duplicated_job_id ?? (job.existing_job_id as number) }),
        {
          filterError: [ApiErrorTypes.NotFound],
          onSuccess: (interview: { id: number; questions: { id: number }[] }) => {
            job.manualSelection.useInterview(true)
            job.manualSelection.setQuestions(interview.questions.map(q => q.id))
            interviewIdRef.current = interview.id
          },
        },
      )

      useQuery(() => getPastSPs({ collect: false }), {
        onError: err => {
          toast.error(err.data.message)
        },
        filterError: [ApiErrorTypes.ClientError],
      })

      const { t } = useTranslation()

      useEffect(() => {
        if (Boolean(orderId)) {
          getOrderSummaryQuery()
        }
        if (job.manualSelection.use_interview) {
          requestInterview()
        }
      }, [orderId])

      useEffect(() => {
        if (pastSPsList.length > 0) {
          job.fields.addInvitedSPsField()
        } else {
          job.fields.removeInvitedSPsField()
        }
      }, [pastSPsList.length])

      const [popupField, setPopupField] = useState<DraftJobFields | null>(activePopupField ?? null)
      const [clientHasLocations, setClientHasLocations] = useState(true)

      const formValuesRef = useRef<JobForm>()
      const createdOrderIdRef = useRef<number>()
      const interviewIdRef = useRef<number>()
      const locationFormRef = useRef<FormikProps<BusinessLocationForm>>(null)
      const formBottomRef = useRef<HTMLDivElement>(null)

      useQuery(() => getPointsOfContact({}))
      useQuery(getUniforms)

      const { isLoading: professionsLoading } = useQuery(getProfessions, {
        onSuccess: () => {
          const workCategory = getProfession(job.category.id as number)

          if (job.description.value) return
          const defaultJobDescription = workCategory?.job_description ?? ''
          job.description.set(defaultJobDescription)
        },
      })
      const {
        enableQuery: loadRequirements,
        query: { isLoading: loadingRequirements },
      } = useDelayedQuery(() => getWorkRequirements(job.category.id as number))

      const currentStep = useMemo(() => {
        if (!popupField) {
          return 0
        }
        return job.fields.list.findIndex(field => field === popupField)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [popupField])

      const [, getInvitedSPsQuery] = useMutation(({ ids }: { ids: number[] }) => getInvitedPastSPsByIds(ids))

      useEffect(() => {
        if (job.requirements.list?.length) {
          loadRequirements()
        }

        if (job.invitedSPs.list?.length) {
          getInvitedSPsQuery({ ids: job.invitedSPs.list })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])

      useEffect(() => {
        setPopupField(activePopupField ?? null)
      }, [activePopupField])

      const redirect = useRedirect()

      const [createJobResult, requestCreateJob] = useMutation(createJob, {
        onSuccess: (createdJob: JobSnapshot) => {
          if (job.manualSelection.use_interview && job.manualSelection.interview_questions?.length) {
            requestCreateInterview({
              jobId: createdJob.id,
              workCategoryId: createdJob.work_category_id,
              questions: job.manualSelection.interview_questions,
            })
            // do not await for the request, redirect right away
          }
          redirect(isExtension ? routes.extendedOrderDraft : routes.orderDraft, {
            orderId: String(createdOrderIdRef.current ?? orderId),
          })
        },
        onError: err => {
          // Makes Sentry to have it as a separate error with all logs/breadcrumbs
          console.error('Create Job API Error')
          handleApiFormErrors(err)
        },
        filterError: [ApiErrorTypes.ClientError],
      })

      const [createOrderResult, requestCreateOrder] = useMutation(createOrder, {
        onSuccess: (createdOrderId: number) => {
          createdOrderIdRef.current = createdOrderId
          requestCreateJob({
            ...formValuesRef.current,
            order_id: createdOrderId,
          } as JobForm)
        },
        onError: () => {
          // Makes Sentry to have it as a separate error with all logs/breadcrumbs
          console.error('Create Order API Error')
        },
      })

      const [updateJobResult, requestUpdateJob] = useMutation(updateJob, {
        onSuccess: () => {
          if (job.manualSelection.use_interview && job.manualSelection.interview_questions?.length) {
            // user decided to use interview
            if (interviewIdRef.current) {
              // interview was created before this session, just update it
              requestUpdateInterview({
                interviewId: interviewIdRef.current,
                workCategoryId: job.category.id as number,
                jobId: job.existing_job_id as number,
                questions: job.manualSelection.interview_questions,
              })
            } else {
              // interview will be created in this session
              requestCreateInterview({
                jobId: job.existing_job_id as number,
                workCategoryId: job.category.id as number,
                questions: job.manualSelection.interview_questions,
              })
            }
          } else if (interviewIdRef.current) {
            // user decided not to use the interview anymore
            requestDeleteInterview({ interviewId: interviewIdRef.current })
          }
          if (isExtension) {
            redirect(routes.extendedOrderDraft, {
              orderId: orderId as string,
            })
          } else {
            redirect(routes.orderDraft, {
              orderId: orderId as string,
              title: orderDraftTitle,
            })
          }
        },
        onError: err => {
          // Makes Sentry to have it as a separate error with all logs/breadcrumbs
          console.error('Update Job API Error')
          handleApiFormErrors(err)
        },
        filterError: [ApiErrorTypes.ClientError],
      })

      const [, requestCreateInterview] = useMutation(createInterview, {
        filterError: Object.values(ApiErrorTypes), // don't interupt job creation process
        onError: () => {
          // Makes Sentry to have it as a separate error with all logs/breadcrumbs
          console.error('Create Interview API Error')
        },
      })

      const [, requestUpdateInterview] = useMutation(updateInterview, {
        filterError: Object.values(ApiErrorTypes), // don't interupt job update process
        onError: () => {
          // Makes Sentry to have it as a separate error with all logs/breadcrumbs
          console.error('Update Interview API Error')
        },
      })

      const [, requestDeleteInterview] = useMutation(deleteInterview, {
        filterError: Object.values(ApiErrorTypes), // don't interupt job creation process
        onError: () => {
          // Makes Sentry to have it as a separate error with all logs/breadcrumbs
          console.error('Delete Interview API Error')
        },
      })

      const handleApiFormErrors = (err: ApiErrorInterface) => {
        if (err.data.errors) {
          // indicate that there is at least one error
          toast.error(err.data.message)
          // show errors in the form
          job.assignApiErrorsToJob(err.data.errors)
        } else {
          toast.error(err.data.message)
        }
      }

      const getDaysLabel = () => {
        if (job.schedule.scheduleType.value === ScheduleType.weekly) {
          return job.schedule.distinctTimeSchedule.jobDatesKeys.map(key => t(`time.${key.toLowerCase()}`)).join(', ')
        } else if (job.schedule.scheduleType.value === ScheduleType.daily) {
          return job.schedule.distinctTimeSchedule.jobDatesKeys
            .map(key => moment(key, 'YYYY-MM-DD').format('DD MMM'))
            .join(', ')
        } else {
          return ''
        }
      }

      const stepsLength = job.fields.list.length
      const isSubmitting = createJobResult.isLoading || updateJobResult.isLoading || createOrderResult.isLoading
      const isLoading = professionsLoading || loadingRequirements

      const setNextField = () => {
        const nextField = job.fields.list[currentStep + 1]
        setPopupField(nextField)
      }

      const handleNextPress = async () => {
        if (popupField === DraftJobFields.location && !clientHasLocations) {
          await locationFormRef.current?.submitForm()
        }
        if (popupField !== DraftJobFields.preferences && !job.validateField(popupField as DraftJobFields)) return
        if (currentStep === stepsLength - 1) {
          setPopupField(null)
          formBottomRef.current?.scrollIntoView({ behavior: 'smooth' })
        } else {
          setNextField()
        }
      }

      const formComponentsParams: FormComponentsPossibleParams = {
        orderId,
        locationFormRef,
        onLocationsLoaded: setClientHasLocations,
        onLocationFormSubmit: () => {
          if (Boolean(locationFormRef.current?.errors)) {
            return
          } else {
            job.location.clearError()
          }

          setNextField()
        },
        job,
      }

      const submitForm = () => {
        const submitObject = {
          work_category_id: job.category.id as number,
          quantity: job.quantity.value as number,
          client_location_id: job.location.value?.id as number,
          start_date: job.schedule.dateBoundaries.start_date as string,
          end_date: job.schedule.dateBoundaries.end_date as string,
          is_recurring: job.schedule.dateBoundaries.open_ended ? 1 : 0,
          contact_user_id: job.manager.id as number,
          work_requirement_ids: job.requirements.list as number[],
          instructions: job.description.value as string,
          seniority_level: Number(job.experience.value),
          gender: job.gender.value ? Number(job.gender.value) : null,
          uniform_id: job.dresscode.id as number,
          invited_sp_ids: job.invitedSPs.list as number[],
          languages: job.languages.list as number[],
          certificates: job.documents.list as number[],
          is_automatic_checkout: 1,
          intervals: job.schedule.distinctTimeSchedule.map as Map<
            string,
            {
              start_time: string
              end_time: string
              selected: boolean
            }
          >,
          assignment_type: 1,
          is_accreditation_required: job.accreditation.value ? 1 : 0,
          is_accommodation_offered: job.accommodation.value ? 1 : 0,
          is_interview_required: job.manualSelection.use_interview ? 1 : 0,
          boarding_type: job.boarding.value ?? 0,
          public_transport_accessibility: job.transport.value,
        }
        if (job.existing_job_id) {
          requestUpdateJob({
            ...submitObject,
            id: job.existing_job_id,
          })
        } else {
          const existingOrderId = Number(orderId)
          if (existingOrderId) {
            requestCreateJob({
              ...submitObject,
              order_id: existingOrderId,
            })
          } else {
            formValuesRef.current = { ...submitObject, order_id: Number(orderId) }
            requestCreateOrder(undefined)
          }
        }
      }

      return (
        <UIComponent
          orderId={Number(orderId)}
          formTitle={getFormTitle(getProfessionName(job.category.id as number) as string)}
          formSubtitle={formSubtitle}
          isLoading={isLoading}
          isSubmitting={isSubmitting}
          job={job}
          onSubmit={onSubmit ?? submitForm}
          professionName={job.category.id ? getProfessionName(job.category.id) : null}
          openField={setPopupField}
          daysLabel={getDaysLabel()}
          workCategoryRequirementsNames={
            job.requirements.list
              ? getWorkCategoryRequirementsNames(job.category.id as number, job.requirements.list)?.join(', ')
              : null
          }
          invitedSPsNames={
            job.invitedSPs.list
              ? invitedPastSPsList
                  .filter(sp => job.invitedSPs.list?.includes(sp.id))
                  .map(sp => sp.name)
                  .join(', ')
              : null
          }
          experienceName={job.experience.value ? getExperienceName(job.experience.value) : null}
          publicTransportAccessibilityName={
            job.transport.value ? t(getPublicTransportAccessibilityName(job.transport.value)) : null
          }
          uniformName={job.dresscode.id ? getUniformName(job.dresscode.id) : null}
          contactFullName={job.manager.id ? getContactFullName(job.manager.id) : null}
          optionalFieldsLabels={getOptionalFieldsLabels(job)}
          languagesNames={job.languages.list ? getLanguagesNames(job.languages.list).join(', ') : null}
          genderName={getGenderName(job.gender.value)}
          jobDocumentsNames={job.documents.list ? getJobDocumentsNames(job.documents.list).join(', ') : null}
          interviewQuestions={
            job.manualSelection.interview_questions
              ? getQuestionNames(job.manualSelection.interview_questions).join(', ')
              : null
          }
          formActions={formActions?.(isSubmitting)}
          popupField={popupField}
          setPopupField={setPopupField}
          formComponentsParams={formComponentsParams}
          currentStep={currentStep}
          stepsLength={stepsLength}
          isStepDisabled={false}
          handleNextPress={() => {
            handleNextPress()
          }}
          handleBackPress={() => {
            const prevField = job.fields.list[currentStep - 1]
            setPopupField(prevField)
          }}
          formBottomRef={formBottomRef}
          showStepIndicator={showStepIndicator}
          stepNumber={stepNumber}
          jobsCount={(orderSummary?.jobs.length ?? 0) + 1}
        />
      )
    },
  )
}
