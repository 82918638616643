import { observer } from 'mobx-react'
import { useTranslation } from 'react-i18next'

import { Header, Separator } from '../../app-layout'
import { Field } from './components/field/field'
import { Popup } from '../../popup/popup'
import { Button } from '../../button/button'
import { renderFormComponent } from '../form-components'
import { Stepper } from '../../stepper/stepper'
import LoadingSpinner from '../../loading-spinner/loading-spinner'
import { UIProps } from '../types'
import './job-draft-details.scss'
import { StepIndicator } from '../../step-indicator/step-indicator'
import { HeaderJobsCounter } from '../../header-jobs-counter/header-jobs-counter'
import { getBoardingLabel } from './components/boarding/get-boarding-label'
import { DraftJobFields } from '../../../models-ui/order/store/job-fields/fields'
import { JobDraftFieldError } from './components/error/error'
import { ActionAlert, Theme } from '../../action-alert/action-alert'
import { convertYyyyMmDdToLocaleDdMmmYyyy } from '../../../utils/datetime-utils'

const stepIndicatorConfig = {
  stepsCount: 3,
  currentStepNumber: 1,
}

/**
 * TODO: - refactor Field components into subcomponents managing it's own logic
 *       - move popupField/setPopupField to job store
 */
function JobDraftDetailsFormUIComponent({
  orderId,
  formTitle,
  formSubtitle,
  isLoading,
  isSubmitting,
  job,
  onSubmit,
  professionName,
  publicTransportAccessibilityName,
  openField,
  daysLabel,
  workCategoryRequirementsNames,
  experienceName,
  uniformName,
  contactFullName,
  invitedSPsNames,
  optionalFieldsLabels,
  languagesNames,
  genderName,
  jobDocumentsNames,
  interviewQuestions,
  formActions,
  popupField,
  setPopupField,
  formComponentsParams,
  currentStep,
  stepsLength,
  isStepDisabled,
  handleNextPress,
  handleBackPress,
  formBottomRef,
  showStepIndicator,
  stepNumber,
  jobsCount,
}: UIProps) {
  const { t } = useTranslation()

  return (
    <div className="JobDraftDetails">
      <Header
        title={formTitle}
        rightComponent={<HeaderJobsCounter jobsCount={Number(jobsCount)} orderId={orderId} />}
        showBack
      />
      {(job.schedule.sameTimeSchedule.is_less_than_24h ?? job.schedule.distinctTimeSchedule.isLessThan24Hours) && (
        <ActionAlert
          theme={Theme.Orange}
          title={t('job_draft_details.less_than_24h_warning')}
          action={() => {}}
          isActionInProgress={false}
          withCloseButton={false}
          style={{ marginTop: '50px' }}
        />
      )}
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <Separator.Vertical height={35} />
          {showStepIndicator && (
            <StepIndicator
              stepsCount={stepIndicatorConfig.stepsCount}
              currentStepNumber={stepNumber ?? stepIndicatorConfig.currentStepNumber}
            />
          )}
          <span className="JobDraftDetails__title">{formSubtitle}</span>
          <Separator.Vertical height={16} />
          {job.fields.hasField(DraftJobFields.category) && (
            <Field
              content={job.category.id ? professionName : null}
              placeholder={t('job_draft_details.staff')}
              onPress={() => openField(DraftJobFields.category)}
              error={job.category.error}
            />
          )}
          <Field
            content={job.quantity.value !== null ? String(job.quantity.value) : null}
            placeholder={t('job_draft_details.quantity')}
            onPress={() => openField(DraftJobFields.quantity)}
            error={job.quantity.error}
          />
          <Field
            content={job.location.value?.name}
            placeholder={t('job_draft_details.location')}
            onPress={() => openField(DraftJobFields.location)}
            error={job.location.error}
          />
          <div className="JobDraftDetails__row">
            <Field
              content={
                job.schedule.dateBoundaries.start_date
                  ? convertYyyyMmDdToLocaleDdMmmYyyy(job.schedule.dateBoundaries.start_date)
                  : null
              }
              placeholder={t('job_draft_details.start_date')}
              onPress={() => openField(DraftJobFields.dateBoundaries)}
              error={job.schedule.dateBoundaries.start_date_error}
            />
            {job.schedule.dateBoundaries.open_ended !== true && (
              <>
                <Separator.Horizontal width={11} />
                <Field
                  content={
                    job.schedule.dateBoundaries.end_date
                      ? convertYyyyMmDdToLocaleDdMmmYyyy(job.schedule.dateBoundaries.end_date)
                      : null
                  }
                  placeholder={t('job_draft_details.end_date')}
                  onPress={() => openField(DraftJobFields.dateBoundaries)}
                  error={job.schedule.dateBoundaries.end_date_or_open_ended_error}
                />
              </>
            )}
          </div>
          <Field
            content={daysLabel}
            placeholder={t('job_draft_details.days')}
            onPress={() => {
              openField(DraftJobFields.daysSelect)
            }}
            error={job.schedule.anyScheduleError}
          />
          {job.schedule.sameTimeSchedule.allDaysHaveSameTime &&
            job.schedule.sameTimeSchedule.same_start_time &&
            job.schedule.sameTimeSchedule.same_end_time && (
              <div className="JobDraftDetails__row">
                <Field
                  content={job.schedule.sameTimeSchedule.same_start_time}
                  placeholder={t('job_draft_details.start_time')}
                  onPress={() => openField(DraftJobFields.sameTimeSchedule)}
                  error={job.schedule.sameTimeSchedule.same_start_time_error}
                />
                <Separator.Horizontal width={11} />
                <Field
                  content={job.schedule.sameTimeSchedule.same_end_time}
                  placeholder={t('job_draft_details.end_time')}
                  onPress={() => openField(DraftJobFields.sameTimeSchedule)}
                  error={job.schedule.sameTimeSchedule.same_end_time_error}
                />
              </div>
            )}
          <Field
            content={workCategoryRequirementsNames}
            placeholder={t('job_draft_details.requirements')}
            onPress={() => openField(DraftJobFields.requirements)}
            error={job.requirements.error}
          />
          <Field
            content={job.description.value}
            placeholder={t('job_draft_details.job_description')}
            onPress={() => openField(DraftJobFields.description)}
            error={job.description.error}
          />
          <Field
            content={experienceName}
            placeholder={t('job_draft_details.experience')}
            onPress={() => openField(DraftJobFields.experience)}
            error={job.experience.error}
          />
          <Field
            content={uniformName}
            placeholder={t('job_draft_details.dresscode')}
            onPress={() => openField(DraftJobFields.dresscode)}
            error={job.dresscode.error}
          />
          <Field
            content={contactFullName}
            placeholder={t('job_draft_details.point_of_contact')}
            onPress={() => openField(DraftJobFields.contact)}
            error={job.manager.error}
          />
          {job.fields.hasField(DraftJobFields.invitedSPs) && (
            <Field
              content={invitedSPsNames}
              placeholder={t('invited_sps.invited_sps')}
              onPress={() => openField(DraftJobFields.invitedSPs)}
              error={job.invitedSPs.error}
            />
          )}
          <Field
            content={optionalFieldsLabels}
            placeholder={t('job_draft_details.additional_preferences')}
            onPress={() => openField(DraftJobFields.preferences)}
          />
          {job.languages.use_languages && (
            <Field
              content={languagesNames}
              placeholder={t('job_draft_details.languages')}
              onPress={() => openField(DraftJobFields.languages)}
              error={job.languages.error}
            />
          )}
          {job.gender.use_gender && (
            <Field
              content={genderName}
              placeholder={t('job_draft_details.gender')}
              onPress={() => openField(DraftJobFields.gender)}
              error={job.gender.error}
            />
          )}
          {job.documents.use_documents && (
            <Field
              content={jobDocumentsNames}
              placeholder={t('job_draft_details.additional_documents')}
              onPress={() => openField(DraftJobFields.documents)}
              error={job.documents.error}
            />
          )}
          {job.manualSelection.use_interview && (
            <Field
              content={interviewQuestions}
              placeholder={t('job_draft_details.preferences_interview')}
              onPress={() => openField(DraftJobFields.videoInterviews)}
              error={job.manualSelection.interview_error}
            />
          )}
          {job.accreditation.use_accreditation && (
            <Field
              content={
                job.accreditation.value === null
                  ? ''
                  : job.accreditation.value
                    ? t('job_draft_details.accreditation_yes')
                    : t('job_draft_details.accreditation_no')
              }
              placeholder={t('job_draft_details.accreditation')}
              onPress={() => openField(DraftJobFields.accreditation)}
              error={job.accreditation.error}
            />
          )}
          {job.accommodation.use_accommodation && (
            <Field
              content={
                job.accommodation.value === null
                  ? ''
                  : job.accommodation.value
                    ? t('job_draft_details.accommodation_yes')
                    : t('job_draft_details.accommodation_no')
              }
              placeholder={t('job_draft_details.preferences_accommodation_offered')}
              onPress={() => openField(DraftJobFields.preferences)}
              error={job.accommodation.error}
            />
          )}
          {job.boarding.use_boarding && (
            <Field
              content={job.boarding.value === null ? '' : getBoardingLabel(job.boarding.value)}
              placeholder={t('job_draft_details.preferences_boarding_offered')}
              onPress={() => openField(DraftJobFields.boarding)}
              error={job.boarding.error}
            />
          )}
          <JobDraftFieldError error={job.error} />
          {formActions ?? (
            <>
              <Separator.Vertical height={36} />
              <Button
                disabled={job.is_validating_form || isSubmitting}
                title={job.is_validating_form ? t('job_draft_details.submitting') : t('job_draft_details.save')}
                type="button"
                className="JobDraftDetails__submitButton"
                onClick={() => {
                  const isValid = job.validateForm()
                  if (isValid) {
                    onSubmit()
                  }
                }}
              />
            </>
          )}
          <div ref={formBottomRef}></div>
          <Separator.Vertical height={42} />
        </>
      )}
      <Popup
        isActive={popupField !== null}
        close={() => {
          setPopupField(null)
        }}
        content={
          popupField ? (
            <>
              {renderFormComponent(popupField, formComponentsParams)}
              <Separator.Vertical height={20} />
              <Stepper
                currentStep={currentStep}
                stepsCount={stepsLength}
                backDisabled={currentStep === 0}
                nextDisabled={isStepDisabled}
                handleNextPress={handleNextPress}
                handleBackPress={handleBackPress}
                nextButtonLabel={
                  currentStep === stepsLength - 1 ? t('job_draft_details.finish') : t('job_draft_details.next')
                }
                className="JobDraftDetails__stepper"
              />
            </>
          ) : null
        }
      />
    </div>
  )
}

export const JobDraftDetailsFormUI = observer(JobDraftDetailsFormUIComponent)
